import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import img from '../../images/404error.png';
import './error.scss';
import { useState } from 'react';
import { useEffect } from 'react';

export const PageNotFound = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useState('');

    const urlSearch = () => {
        const split = location.search.split('&');
        const index = split.findIndex((elem) => elem.includes('ref'));
        split.splice(index, 1);
        const res = split.map((elem, index) => {
            if (index === 0) {
                return elem;
            } else {
                return `&${elem}`;
            }
        });
        const result = res.join('');
        setSearchParams(result);
    };

    useEffect(() => {
        if (!location) return;
        urlSearch();
    }, [location]);

    return (
        <>
            <Header user={user} />
            <div className="err-page">
                <div className="err-page__container">
                    <img src={img} className="err-page__img" />
                    <div className="err-page__title">
                        This is not the page you’re looking for
                    </div>
                    <div className="err-page__subtitle">
                        Double-check the address, you may have mistyped the URL
                        or the page has moved
                    </div>
                    <div
                        className="err-page__btn"
                        onClick={() => navigate(`/home${searchParams}`)}
                    >
                        Go back
                    </div>
                </div>
            </div>
        </>
    );
};
