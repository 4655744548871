export const stepsFunc = (formData, user, setSteps) => {
    if (
        (formData.marital_status === 'married/de_facto' ||
            formData.marital_status === 'engaged') &&
        formData.number_investment_properties == '0'
    ) {
        if (user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Partners Income & Super',
                },
            ]);
        }
        if (!user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Partners Income & Super',
                },
                {
                    id: 6,
                    title: 'Personal Info',
                },
            ]);
        }
    }
    if (
        (formData.marital_status === 'married/de_facto' ||
            formData.marital_status === 'engaged') &&
        formData.number_investment_properties > 0
    ) {
        if (user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Partners Income & Super',
                },
                {
                    id: 6,
                    title: 'Investment Properties',
                },
            ]);
        }

        if (!user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Partners Income & Super',
                },
                {
                    id: 6,
                    title: 'Investment Properties',
                },
                {
                    id: 7,
                    title: 'Personal Info',
                },
            ]);
        }
    }
    if (
        formData.marital_status !== 'married/de_facto' &&
        formData.marital_status !== 'engaged' &&
        formData.number_investment_properties === 0
    ) {
        if (user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
            ]);
        }
        if (!user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },

                {
                    id: 5,
                    title: 'Personal Info',
                },
            ]);
        }
    }
    if (
        formData.marital_status !== 'married/de_facto' &&
        formData.marital_status !== 'engaged' &&
        formData.number_investment_properties > 0
    ) {
        if (user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Investment Properties',
                },
            ]);
        }
        if (!user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 5,
                    title: 'Investment Properties',
                },
                {
                    id: 6,
                    title: 'Personal Info',
                },
            ]);
        }
    }
    if (
        formData.marital_status !== 'married/de_facto' &&
        formData.marital_status !== 'engaged' &&
        formData.number_investment_properties === '0'
    ) {
        if (user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
            ]);
        }
        if (!user.is_referrer_found) {
            setSteps([
                {
                    id: 1,
                    title: 'Living Situation',
                },
                {
                    id: 2,
                    title: 'Assets',
                },
                {
                    id: 3,
                    title: 'Liabilities',
                },
                {
                    id: 4,
                    title: 'Income & Super',
                },
                {
                    id: 6,
                    title: 'Personal Info',
                },
            ]);
        }
    }
};
