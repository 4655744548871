import React, { useState } from 'react';
import moment from 'moment/moment';
import arrow from '../../images/arrow-bottom.png';
import './footer.scss';
import { useLocation, useNavigate } from 'react-router-dom';

export const Footer = ({ user }) => {
    const [showText, setShowText] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <div className="footer">
                <div className="footer-container">
                    <div
                        className="footer__row row"
                        style={{ margin: ' 20px 0 0 0' }}
                    >
                        <div className="row__text">
                            © NWA Group Pty Ltd (National Wealth Advisory) 2020
                            | ABN: 17 638 469 495 | Terms and Conditions |
                            Privacy Policy Mortgage and Finance services
                            provided under Allied Mortgage Corporation Australia
                            Pty Ltd ABN 71 126 679 294 ACL No. 386153. NWA Group
                            Pty Ltd Home Loans service is powered by Australian
                            Credit Licence 384324. NWA Group Pty Ltd receives a
                            commission for making these referrals. The
                            commission that we receive depends upon the lender
                            that ultimately provides your home loan and will be
                            disclosed by our Lending Manager as part of
                            arranging your home loan. By completing your details
                            above you consent to HQ Corp Australia Pty Ltd (ABN:
                            73 154 804 421) & NWA Group Pty Ltd (ABN: 17 638 469
                            495) and it's Marketing Partners using your contact
                            details to email & call you to deliver you
                            information and promotional material concerning
                            third-party offers including but not limited to
                            financial products and/or services on offer.
                            Disclaimer: Information published on this website
                            has been prepared for general information purposes
                            only and not as specific advice to any particular
                            person. Any advice put forward here and contained in
                            this compilation document is General Advice and does
                            not take into account any person’s particular needs.
                            Before making an investment decision based on this
                            advice you should consider, with or without the
                            assistance of a qualified professional adviser,
                            whether it is appropriate to your particular
                            investment needs, goals, objectives and financial
                            circumstances.
                        </div>
                    </div>
                    <div className="footer__row row revers">
                        <div className="row__text">
                            <div
                                className="row__text-item"
                                style={{ width: '125px' }}
                            >
                                Copyright © {moment().format('YYYY')}
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div
                                    style={{ display: 'flex' }}
                                    className="row__text-wrap"
                                >
                                    <div
                                        className="row__text-item"
                                        style={{ width: '120px' }}
                                    >
                                        +61 1300 268 954
                                    </div>

                                    <div
                                        className="row__text-item"
                                        style={{ width: '245px' }}
                                    >
                                        info@nationalwealthadvisory.com.au
                                    </div>
                                </div>

                                <div
                                    className="row__text-item"
                                    style={{ width: '215px' }}
                                >
                                    2C / 17 Short, Southport QLD 4215
                                </div>
                            </div>
                        </div>
                        <div className="row__links links">
                            <a
                                className="links__link"
                                onClick={() => {
                                    if (
                                        location.pathname.includes(
                                            'privacy-policy'
                                        )
                                    ) {
                                        return;
                                    }
                                    navigate(
                                        `/privacy-policy${location?.search}`,
                                        { state: { from: location.pathname } }
                                    );
                                }}
                                style={
                                    location.pathname.includes('privacy-policy')
                                        ? {
                                              cursor: 'context-menu',
                                          }
                                        : {}
                                }
                            >
                                Privacy Policy
                            </a>
                            <a
                                className="links__link"
                                onClick={() => {
                                    if (location.pathname.includes('terms')) {
                                        return;
                                    }
                                    navigate(
                                        `/terms${location?.search}`,
                                        { state: { from: location.pathname } }
                                    );
                                }}
                                style={
                                    location.pathname.includes('terms')
                                        ? {
                                              width: '130px',
                                              cursor: 'context-menu',
                                          }
                                        : {
                                              width: '130px',
                                          }
                                }
                            >
                                Terms & Conditions
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
