import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header/Header';
import sun from '../../images/sun.png';
import mark from '../../images/mark.png';
import './Congratulations.scss';
import { Footer } from '../../components/Footer/Footer';
import arrowupright from '../../images/arrow-up-right.png';
import { Calendar } from '../../components/Calendar/Calendar';

export const Congratulations = ({ user, setLoading }) => {
  const screenWidth = window.innerWidth;
  const [res, setRes] = useState('');
  document.title = 'Incentives Calculator Results';

  const result = localStorage.getItem('resultPage');
  const congratulations = JSON.parse(result);

  useEffect(() => {
    if (!congratulations) return;
    if (congratulations?.investment_strategy.page === '2A') {
      setRes('2A');
    }
    if (congratulations?.investment_strategy.page === '2B') {
      setRes('2B');
    }
    if (congratulations?.investment_strategy.page === '2C') {
      setRes('2C');
    }
    if (congratulations?.investment_strategy.page === '2E') {
      setRes('2E');
    }
    if (congratulations?.investment_strategy.page === '2D') {
      if (
        congratulations?.investment_strategy.additional_info.text_option === 1
      ) {
        setRes('2D1');
      }
      if (
        congratulations?.investment_strategy.additional_info.text_option === 2
      ) {
        setRes('2D2');
      }
      if (
        congratulations?.investment_strategy.additional_info.text_option === 3
      ) {
        setRes('2D3');
      }
      if (
        congratulations?.investment_strategy.additional_info.text_option === 4
      ) {
        setRes('2D4');
      }
    }
  }, [congratulations]);

  return (
    <>
      <Header user={user} />
      <div className="congratulations">
        <div className="container-congratulations">
          <div>
            {res !== '2E' ? (
              <div className="congratulations__title">
                congratulati
                <div className="congratulations__title-img">
                  <img src={sun} className="congratulations__sun" />
                  <img className="congratulations__mark" src={mark} />
                </div>
                ns!
              </div>
            ) : (
              <div
                className="congratulations__title"
                style={{ lineHeight: '120%' }}
              >
                Unfortunately we can't assist at this time
              </div>
            )}
            <div className="congratulations__main-wrap">
              <div className="congratulations__main-block">
                <div
                  className="congratulations__subtitle"
                  style={
                    (res === '2D1' ||
                      res === '2D2' ||
                      res === '2D3' ||
                      res === '2D4' ||
                      res === '2E') &&
                    screenWidth > 1000
                      ? { padding: '0 0 40px 0' }
                      : {}
                  }
                >
                  {res === '2A' && (
                    <>
                      Based on your numbers you have an estimated personal
                      borrowing capacity of{' '}
                      <b>
                        $
                        {congratulations?.calculation_investments_ability?.result_personal?.purchase_price
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , and therefore can leverage Government tax incentives to
                      pay down your home years faster. 90% of the time, we help
                      our clients get their home mortgage down to 7-10 years.
                    </>
                  )}
                  {res === '2B' && (
                    <>
                      Based on your numbers you have an estimated Superannuation
                      borrowing capacity of
                      <b>
                        {' '}
                        $
                        {congratulations?.calculation_investments_ability?.result_super.purchase_price
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , we can show you how it's possible to purchase investment
                      property using your superannuation, and how to take
                      advantage of the various tax and contribution incentives
                      available when structured the right way. Ultimately with
                      the goal to become debt free by creating passive income
                      producing assets that can help secure retirement.
                    </>
                  )}
                  {res === '2C' && (
                    <>
                      <p>
                        {' '}
                        Based on your numbers you have an estimated personal
                        borrowing capacity of
                        <b>
                          {' '}
                          $
                          {congratulations?.calculation_investments_ability?.result_personal?.purchase_price
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </b>
                        , and therefore can leverage Government tax incentives
                        to pay down your home years faster. 90% of the time, we
                        help our clients get their home mortgage down to 7-10
                        years.
                      </p>
                      <p>
                        You also have an estimated Superannuation borrowing
                        capacity of
                        <b>
                          {' '}
                          $
                          {congratulations?.calculation_investments_ability?.result_super?.purchase_price
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </b>
                        , we can show you how it's possible to purchase
                        investment property using your superannuation, and how
                        to take advantage of the various tax and contribution
                        incentives available when structured the right way.
                      </p>
                    </>
                  )}
                  {res === '2D1' && (
                    <>
                      Based on your combined Super balance of
                      <b>
                        {' '}
                        $
                        {congratulations?.request_data?.combined_super
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , you qualify for a FREE Super Health Check, where a
                      Personal Wealth Specialist will provide a:
                    </>
                  )}
                  {res === '2D2' && (
                    <>
                      Congratulations, based on your Super balance of
                      <b>
                        {' '}
                        $
                        {congratulations?.request_data?.super_balance_1
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , you qualify for a FREE Super Health Check, where a
                      Personal Wealth Specialist will provide a:
                    </>
                  )}
                  {res === '2D3' && (
                    <>
                      Congratulations, based on your partners Super balance of
                      <b>
                        {' '}
                        $
                        {congratulations?.request_data?.super_balance_2
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , you qualify for a FREE Super Health Check, where a
                      Personal Wealth Specialist will provide a:
                    </>
                  )}
                  {res === '2D4' && (
                    <>
                      Congratulations, based on your Super balance of
                      <b>
                        {' '}
                        $
                        {congratulations?.request_data?.combined_super
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , you qualify for a FREE Super Health Check, where a
                      Personal Wealth Specialist will provide a:
                    </>
                  )}
                  {res === '2E' && (
                    <>
                      To take advantage of these Government tax incentives by
                      leveraging an investment property, you need a minimum
                      borrowing capacity of
                      <b>
                        {' '}
                        $
                        {congratulations?.calculation_investments_ability?.result_personal.purchase_price
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </b>
                      , to do this you need both enough available equity and the
                      ability to service the loan.
                    </>
                  )}
                </div>
                <div
                  className={`congratulations__row-top row-top ${
                    (res === '2D1' ||
                      res === '2D2' ||
                      res === '2D3' ||
                      res === '2D4') &&
                    screenWidth < 1000
                      ? 'row-top-2D'
                      : ''
                  } ${res === '2E' ? 'row-top-2E' : ''}`}
                  style={
                    (res === '2D1' ||
                      res === '2D2' ||
                      res === '2D3' ||
                      res === '2D4') &&
                    screenWidth > 1000
                      ? {
                          borderTop: '1px solid #D2DEE5',
                        }
                      : {}
                  }
                >
                  {screenWidth >= 1300 && (
                    <>
                      {res === '2A' && (
                        <div className="row-top__text">
                          <>
                            Book in to speak with one of our Personal Wealth
                            Specialists, where you will find out how to take
                            advantage of these incentives, exactly how fast you
                            can pay down your mortgage, become debt free faster,
                            create a passive income and secure your retirement.
                          </>
                        </div>
                      )}
                      {res === '2B' && (
                        <div className="row-top__text">
                          <>
                            Book in to speak with one of our Personal Wealth
                            Specialists, where you will find out how to take
                            advantage of these incentives, exactly how fast you
                            can pay down your mortgage, become debt free faster,
                            create a passive income and secure your retirement.
                          </>
                        </div>
                      )}
                      {res === '2C' && (
                        <div className="row-top__text">
                          <>
                            Book in to speak with one of our Personal Wealth
                            Specialists, where you will find out how to take
                            advantage of these incentives, exactly how fast you
                            can pay down your mortgage, become debt free faster,
                            create a passive income and secure your retirement.
                          </>
                        </div>
                      )}

                      {res === '2E' && (
                        <>
                          <div
                            className={`row-top__item-text ${
                              res === '2E' ? 'e' : ''
                            }`}
                          >
                            <div className="row-top__e-title">
                              Below are your equity and loan servicing results:
                            </div>
                            <div className="row-top__e-subtitle">
                              <div
                                className={
                                  congratulations
                                    ?.calculation_investments_ability
                                    ?.result_personal?.is_qualified_by_equity
                                    ? 'row-top__e-plus'
                                    : 'row-top__e-minus'
                                }
                              >
                                {congratulations
                                  ?.calculation_investments_ability
                                  ?.result_personal?.is_qualified_by_equity ? (
                                  <img src={mark} />
                                ) : (
                                  '-'
                                )}
                              </div>
                              <div>Equity</div>
                            </div>
                            <div className="row-top__e-subtitle">
                              <div
                                className={
                                  congratulations
                                    ?.calculation_investments_ability
                                    ?.result_personal?.is_qualified_by_servicing
                                    ? 'row-top__e-plus'
                                    : 'row-top__e-minus'
                                }
                              >
                                {congratulations
                                  ?.calculation_investments_ability
                                  ?.result_personal
                                  ?.is_qualified_by_servicing ? (
                                  <img src={mark} />
                                ) : (
                                  '-'
                                )}
                              </div>
                              <div>Loan Servicing</div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {(res === '2D1' ||
                    res === '2D2' ||
                    res === '2D3' ||
                    res === '2D4') && (
                    <div className="row-top__text">
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>
                          Superannuation health check using a Government
                          superannuation calculator.
                        </div>
                      </div>
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>
                          An overview of your current super fund, their fees and
                          performance.
                        </div>
                      </div>
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>
                          The difference between wholesale & retail funds.
                        </div>
                      </div>
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>
                          Investment performance comparisons, fund rules &
                          risks.
                        </div>
                      </div>
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>
                          Comparative potential outcomes targeting higher
                          returns.
                        </div>
                      </div>
                      <div className="row-top__item item">
                        <div className="item__mark">
                          <img src={mark} />
                        </div>

                        <div>Fees, processes & Retirement projections.</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="congratulations__btn-block btn-block">
                {res !== '2E' ? (
                  <>
                    {(res === '2D1' ||
                      res === '2D2' ||
                      res === '2D3' ||
                      res === '2D4' ||
                      res === '2A' ||
                      res === '2B' ||
                      res === '2C') && <Calendar res={res} />}
                  </>
                ) : (
                  <div className="congratulations__a">
                    <a
                      className="btn-block__btn"
                      href="https://nationalwealthadvisory.com.au/"
                      target="_blanc"
                    >
                      NWA Website
                      <img src={arrowupright} />
                    </a>
                  </div>
                )}
              </div>

              {screenWidth < 1300 && (
                <div className="row-top__text">
                  {res === '2A' && (
                    <>
                      Book in to speak with one of our Personal Wealth
                      Specialists, where you will find out how to take advantage
                      of these incentives, exactly how fast you can pay down
                      your mortgage, become debt free faster, create a passive
                      income and secure your retirement.
                    </>
                  )}
                  {res === '2B' && (
                    <>
                      Book in to speak with one of our Personal Wealth
                      Specialists, where you will find out how to take advantage
                      of these incentives, exactly how fast you can pay down
                      your mortgage, become debt free faster, create a passive
                      income and secure your retirement.
                    </>
                  )}
                  {res === '2C' && (
                    <>
                      Book in to speak with one of our Personal Wealth
                      Specialists, where you will find out how to take advantage
                      of these incentives, exactly how fast you can pay down
                      your mortgage, become debt free faster, create a passive
                      income and secure your retirement.
                    </>
                  )}

                  {res === '2E' && (
                    <>
                      <div
                        className={`row-top__item-text ${
                          res === '2E' ? 'e' : ''
                        }`}
                      >
                        <div className="row-top__e-title">
                          Below are your equity and loan servicing results:
                        </div>
                        <div className="row-top__e-subtitle">
                          <div
                            className={
                              congratulations?.calculation_investments_ability
                                ?.result_personal?.is_qualified_by_equity
                                ? 'row-top__e-plus'
                                : 'row-top__e-minus'
                            }
                          >
                            {congratulations?.calculation_investments_ability
                              ?.result_personal?.is_qualified_by_equity ? (
                              <img src={mark} />
                            ) : (
                              '-'
                            )}
                          </div>
                          <div>Equity</div>
                        </div>
                        <div className="row-top__e-subtitle">
                          <div
                            className={
                              congratulations?.calculation_investments_ability
                                ?.result_personal?.is_qualified_by_servicing
                                ? 'row-top__e-plus'
                                : 'row-top__e-minus'
                            }
                          >
                            {congratulations?.calculation_investments_ability
                              ?.result_personal?.is_qualified_by_servicing ? (
                              <img src={mark} />
                            ) : (
                              '-'
                            )}
                          </div>
                          <div>Loan Servicing</div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
            {res === '2A' || res === '2B' || res === '2C' ? (
              <div className="congratulations__row-bottom row-bottom">
                <div className="row-bottom__wrapper wrapper">
                  <div className="wrapper__wrap">
                    <div className="wrapper__title">
                      NOT READY TO BOOK YOUR STRATEGY SESSION?
                    </div>
                    <div className="wrapper__subtitle">
                      Well, Greg Morris, our Founder, and Managing Director has
                      also created a short 19 minute information packed video
                      explaining exactly what you will get out of this session.
                    </div>
                  </div>
                  {screenWidth > 1000 && (
                    <div className="wrapper__attention attention">
                      <div className="attention__i">i</div>
                      <div className="attention__text">
                        We’ve also emailed, and sms’d you a link to this video,
                        along with a link to book your FREE 1-Hour Strategy
                        Session.
                      </div>
                    </div>
                  )}
                </div>
                <div className="row-bottom__frame">
                  <iframe
                    allowfullscreen="allowfullscreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/lJYg5jk1tm4"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                  ></iframe>
                  {screenWidth <= 1000 && (
                    <div className="wrapper__attention attention">
                      <div className="attention__i">i</div>
                      <div className="attention__text">
                        We’ve also emailed, and sms’d you a link to this video,
                        along with a link to book your FREE 1-Hour Strategy
                        Session.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
