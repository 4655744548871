import axios from 'axios';
const token = localStorage.getItem('cow-token');

const BASE_URL = process.env.REACT_APP_BASE_URL;

const service = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('cow-token')}`,
    },
});

service.interceptors.request.use(
    (request) => {
        request.headers.Authorization = `Bearer ${localStorage.getItem(
            'cow-token'
        )}`;
        return request;
    },
    (error) => {
        return error;
    }
);

service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status) {
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem('cow-token');
            }
        }

        return Promise.reject(error);
    }
);

export default service;
