import axios from 'axios';
import service from './service';
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const personIdentification = (id) => {
  return axios.get(
    `${BASE_URL}api/person-identification?${id ? `ref=${id}` : ''}`
  );
};

export const PostFormData = (data) => {
  return service.post(`${BASE_URL}api/choice-investment-strategy`, data);
};

export const postCalendar = (data) => {
  return service.post(`${BASE_URL}api/booking_app/appointments/store`, data);
};

export const checkAppiontment = (id) => {
  return service.get(`${BASE_URL}api/booking_app/appointments/get/${id}`);
};
