import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './TextField.scss';

export const TextField = ({
    placeholder,
    maxLength,
    dollar,
    onChange,
    value,
    errorText,
    type = 'number',
    error,
    className,
    onBlur = () => {},
    style,
    inputMode,
    classNameForPlaceHolder,
    classNameForInput,
}) => {
    const [focus, setFocus] = useState(false);

    const currencyMask = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        // value = value.replace(/(\d)(\d{2})$/, '$1.$2');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e;
    };

    return (
        <div className={`text-field ${className} ${classNameForPlaceHolder}`}>
            <input
                inputMode={inputMode}
                style={style}
                className={`text-field__input ${focus ? 'focus' : ''} ${
                    errorText ? 'error' : ''
                } ${error ? 'error' : ''} ${classNameForInput}`}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => {
                    setFocus(false);
                    onBlur();
                }}
                value={
                    type === 'text'
                        ? classNameForPlaceHolder
                            ? value
                                  ?.toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                            : value
                        : value
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                onChange={(e) => {
                    if (type === 'text') {
                        onChange(e);
                    } else {
                        onChange(currencyMask(e));
                    }
                }}
                maxLength={maxLength}
            />
            <div className="text-field__error">{errorText}</div>
            <div
                className={`placeholder ${
                    value || focus ? 'focus' : ''
                } ${classNameForPlaceHolder}`}
            >
                {placeholder}
            </div>
            {dollar ? <div className="text-field__dollar">$</div> : ''}
        </div>
    );
};
