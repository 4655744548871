import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import './PrivacyPolicy.scss';

export const PrivacyPolicy = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goBack = () => {
        let res = '';
        if (location?.state?.from === '/privacy-policy') {
            res = 'Privacy Policy';
        }
        if (location?.state?.from === '/home') {
            res = 'Hero Page';
        }
        if (location?.state?.from.includes('/result_page')) {
            res = 'Result Page';
        }
        if (location?.state?.from === '/terms') {
            res = 'Terms of service';
        }
        return res;
    };

    return (
        <>
            <Header user={user} />
            <div className="privacy">
                <div className="container">
                    <div className="privacy__wrap">
                        <div className="privacy__nav">
                            <div
                                onClick={() => navigate(-1)}
                                style={{
                                    cursor: 'pointer',
                                    padding: '0 5px 0 0',
                                }}
                            >
                                {goBack()}
                            </div>
                            / <div>Privacy policy</div>
                        </div>
                        <div className="privacy__title">Privacy Policy</div>

                        <div className="privacy__main">
                            <p style={{ margin: '0 0 14px 0' }}>
                                Your privacy is important to us. It is National
                                Wealth Advisory Pty Ltd's policy to respect your
                                privacy regarding any information we may collect
                                from you across our websites,{' '}
                                <a
                                    href="https://nationalwealthadvisory.com.au"
                                    target="_blanc"
                                >
                                    https://nationalwealthadvisory.com.au
                                </a>
                                , and other sites we own and operate.
                            </p>
                            <p>
                                We only ask for personal information when we
                                truly need it to provide a service to you. We
                                collect it by fair and lawful means, with your
                                knowledge and consent. We also let you know why
                                we’re collecting it and how it will be used.
                            </p>
                            <p>
                                We only retain collected information for as long
                                as necessary to provide you with your requested
                                service. What data we store, we’ll protect
                                within commercially acceptable means to prevent
                                loss and theft, as well as unauthorised access,
                                disclosure, copying, use or modification.
                            </p>
                            <p>
                                We don’t share any personally identifying
                                information publicly or with third-parties,
                                except when required to by law.
                            </p>
                            <p>
                                Our website may link to external sites that are
                                not operated by us. Please be aware that we have
                                no control over the content and practices of
                                these sites, and cannot accept responsibility or
                                liability for their respective privacy policies.
                            </p>
                            <p>
                                You are free to refuse our request for your
                                personal information, with the understanding
                                that we may be unable to provide you with some
                                of your desired services.
                            </p>
                            <p style={{ margin: '0 0 0 0' }}>
                                Your continued use of our website will be
                                regarded as acceptance of our practices around
                                privacy and personal information. If you have
                                any questions about how we handle user data and
                                personal information, feel free to contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer user={user} />
        </>
    );
};
