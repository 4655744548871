import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import arrow from '../../images/arrow.svg';
import { CustomSelection } from '../CustomSelection/CustomSelection';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import successCalendar from '../../images/successCalendar.svg';
import './Calendar.scss';
import { checkAppiontment, postCalendar } from '../../crud/crud';
import loader from '../../images/small-loader.png';

export const Calendar = ({ res, setLoading }) => {
  const [SelectedDate, setSelectDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const options = ['QLD', 'NSW', 'TAS', 'VIC', 'ACT', 'WA', 'NT', 'SA'];
  const [timeZone, setTimeZone] = useState('');
  const timeZoneConst = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [array, setArray] = useState([]);
  const [hour, setHour] = useState('');
  const zones = [
    { value: 'Australia/Brisbane', id: 'QLD' },
    { value: 'Australia/Sydney', id: 'NSW' },
    { value: 'Australia/Sydney', id: 'TAS' },
    { value: 'Australia/Sydney', id: 'VIC' },
    { value: 'Australia/Sydney', id: 'ACT' },
    { value: 'Australia/Perth', id: 'WA' },
    { value: 'Australia/Darwin', id: 'NT' },
    { value: 'Australia/Adelaide', id: 'SA' },
  ];
  const screenWidth = window.innerWidth;
  const [success, setSuccess] = useState(false);
  const [click, setClick] = useState(false);
  const result = localStorage.getItem('resultPage');
  const congratulations = JSON.parse(result);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (!congratulations?.person?.id) return;
    checkAppiontment(congratulations?.person?.id)
      .then((res) => {
        setSelectDate(res.data.record.date);
        setHour(
          moment(res.data.record.time_client, 'HH:mm:ss').format('h:mm A')
        );
        setSuccess(true);
        setIsChecked(true);
      })
      .catch(() => {
        setIsChecked(true);
      });
  }, [congratulations]);

  const TimeZoneConversion = () => {
    const sourceTime = moment(hour, 'h:mm A');
    const formattedTime = sourceTime.format('HH:mm:ss');
    return formattedTime;
  };

  useEffect(() => {
    if (!isChecked) return;
    const day = moment(SelectedDate).format('dddd');
    let hoursArray = [];

    if (timeZone === 'QLD') {
      if (
        day === 'Monday' ||
        day === 'Tuesday' ||
        day === 'Wednesday' ||
        day === 'Thursday'
      ) {
        hoursArray = [
          '10 AM',
          '12 PM',
          '2 PM',
          '4 PM',
          '6 PM',
          '7:15 PM',
          '8:30 PM',
        ];
        setHour('10 AM');
      }
      if (day === 'Friday' || day === 'Saturday') {
        hoursArray = ['10 AM', '12 PM', '2 PM'];
        setHour('10 AM');
      }
    }
    if (
      timeZone === 'NSW' ||
      timeZone === 'TAS' ||
      timeZone === 'VIC' ||
      timeZone === 'ACT'
    ) {
      if (isDaylightSavingTime) {
        if (
          day === 'Monday' ||
          day === 'Tuesday' ||
          day === 'Wednesday' ||
          day === 'Thursday'
        ) {
          hoursArray = [
            '11 AM',
            '1 PM',
            '3 PM',
            '5 PM',
            '7 PM',
            '8:15 PM',
            '9:30 PM',
          ];
          setHour('11 AM');
        }
        if (day === 'Friday' || day === 'Saturday') {
          hoursArray = ['11 AM', '1 PM', '3 PM'];
          setHour('11 AM');
        }
      } else {
        if (
          day === 'Monday' ||
          day === 'Tuesday' ||
          day === 'Wednesday' ||
          day === 'Thursday'
        ) {
          hoursArray = [
            '10 AM',
            '12 PM',
            '2 PM',
            '4 PM',
            '6 PM',
            '7:15 PM',
            '8:30 PM',
          ];
          setHour('10 AM');
        }
        if (day === 'Friday' || day === 'Saturday') {
          hoursArray = ['10 AM', '12 PM', '2 PM'];
          setHour('10 AM');
        }
      }
    }
    if (timeZone === 'WA') {
      if (
        day === 'Monday' ||
        day === 'Tuesday' ||
        day === 'Wednesday' ||
        day === 'Thursday'
      ) {
        hoursArray = [
          '8 AM',
          '10 AM',
          '12 PM',
          '2 PM',
          '4 PM',
          '5:15 PM',
          '6:30 PM',
        ];
        setHour('8 AM');
      }
      if (day === 'Friday' || day === 'Saturday') {
        hoursArray = ['8 AM', '10 AM', '12 PM'];
        setHour('8 AM');
      }
    }
    if (timeZone === 'NT') {
      if (
        day === 'Monday' ||
        day === 'Tuesday' ||
        day === 'Wednesday' ||
        day === 'Thursday'
      ) {
        hoursArray = [
          '9:30 AM',
          '11:30 AM',
          '1:30 PM',
          '3:30 PM',
          '5:30 PM',
          '6:45 PM',
          '8 PM',
        ];
        setHour('9:30 AM');
      }
      if (day === 'Friday' || day === 'Saturday') {
        hoursArray = ['9:30 AM', '11:30 AM', '1:30 PM'];
        setHour('9:30 AM');
      }
    }
    if (timeZone === 'SA') {
      if (isDaylightSavingTime) {
        if (
          day === 'Monday' ||
          day === 'Tuesday' ||
          day === 'Wednesday' ||
          day === 'Thursday'
        ) {
          hoursArray = [
            '10:30 AM',
            '12:30 PM',
            '2:30 PM',
            '4:30 PM',
            '6:30 PM',
            '7:45 PM',
            '9 PM',
          ];
          setHour('10:30 AM');
        }
        if (day === 'Friday' || day === 'Saturday') {
          hoursArray = ['10:30 AM', '12:30 PM', '2:30 PM'];
          setHour('10:30 AM');
        }
      } else {
        if (
          day === 'Monday' ||
          day === 'Tuesday' ||
          day === 'Wednesday' ||
          day === 'Thursday'
        ) {
          hoursArray = [
            '9:30 AM',
            '11:30 AM',
            '1:30 PM',
            '3:30 PM',
            '5:30 PM',
            '6:45 PM',
            '8 PM',
          ];
          setHour('9:30 AM');
        }
        if (day === 'Friday' || day === 'Saturday') {
          hoursArray = ['9:30 AM', '11:30 AM', '1:30 PM'];
          setHour('9:30 AM');
        }
      }
    }
    setArray(hoursArray);
  }, [SelectedDate, timeZone, isChecked]);

  const [isDaylightSavingTime, setIsDaylightSavingTime] = useState(false);

  useEffect(() => {
    const australiaTimezone = 'Australia/Sydney';
    const currentTime = momentTimezone.tz(australiaTimezone);
    const isDST = currentTime.isDST();

    setIsDaylightSavingTime(isDST);
  }, []);

  useEffect(() => {
    const elem = zones.find((elem) => elem.value === timeZoneConst);
    if (elem) {
      setTimeZone(elem.id);
    } else {
      setTimeZone('QLD');
    }
  }, [timeZoneConst]);

  const booknow = () => {
    setClick(true);
    const data = {
      person_id: congratulations.person.id,
      date: moment(SelectedDate).format('YYYY-MM-DD'),
      // time_qld: moment(convertTimeZone()).format('HH:mm:ss'),
      time_client: TimeZoneConversion(),
      timezone_client: zones.find((elem) => elem.id === timeZone).value,
      state: timeZone,
      investment_strategy: res.includes('2D') ? '2D' : res,
    };
    if (
      congratulations.calculation_investments_ability.result_super.is_qualified
    ) {
      data.result_super = {
        lvr: congratulations.calculation_investments_ability.result_super.lvr,
        purchase_price:
          congratulations.calculation_investments_ability.result_super
            .purchase_price,
      };
    }
    if (
      congratulations.calculation_investments_ability.result_personal
        .is_qualified
    ) {
      data.result_personal = {
        assets_contribution:
          congratulations.calculation_investments_ability.result_personal
            .assets_contribution,
        lvr: congratulations.calculation_investments_ability.result_personal
          .lvr,
        purchase_price:
          congratulations.calculation_investments_ability.result_personal
            .purchase_price,
      };
    }
    postCalendar(data)
      .then(() => {
        setSuccess(true);
      })
      .catch()
      .finally(() => setClick(false));
  };

  const isDayDisabled = (date) => {
    const today = new Date();

    const isSunday = (date) => {
      return date.getDay() === 0; // 0 represents Sunday
    };

    return !isSunday(date);
  };

  return (
    <>
      {isChecked ? (
        <>
          {!success ? (
            <div className="calendar-page">
              <div className="calendar-page__title">
                Free 1 hour Strategy Session
              </div>
              <CustomSelection
                placeholder=""
                style={{ width: '100%', margin: '12px 0 0 0' }}
                styledForArrow={
                  screenWidth > 834
                    ? { top: '24px', left: '541px' }
                    : screenWidth > 550
                    ? { top: '25px', left: '394px' }
                    : { top: '25px', left: '311px' }
                }
                styledForOptions={{
                  width: '100%',
                  zIndex: '2',
                  top: '59px',
                  height: '205px',
                }}
                options={options}
                onChange={setTimeZone}
                value={timeZone}
              />
              <div className="calendar-page__day-title">Date & Time</div>

              <div className="calendar">
                <div className="calendar__hours">
                  <div className="calendar__day">
                    {moment(
                      SelectedDate,
                      'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                    ).format('ddd, MMM DD')}
                  </div>
                  {array?.map((elem) => {
                    return (
                      <div
                        className={`calendar__hour ${
                          hour === elem ? 'active' : ''
                        }`}
                        onClick={() => setHour(elem)}
                      >
                        {elem}
                      </div>
                    );
                  })}
                </div>
                <DatePicker
                  inline
                  className="datepicker"
                  selected={new Date(SelectedDate)}
                  onChange={(date) => {
                    setHour('');
                    setSelectDate(date);
                  }}
                  filterDate={isDayDisabled}
                  startDate={new Date()}
                  calendarStartDay={1}
                  dateFormat="yyyy-MM-dd"
                  showPopperArrow={true}
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() + 1))
                  }
                  maxDate={
                    new Date(new Date().setDate(new Date().getDate() + 14))
                  }
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div className="current-month">
                      <img
                        src={arrow}
                        onClick={decreaseMonth}
                        className="arrow-calendar-left"
                      />
                      <div className="current-month__month">
                        {date?.toLocaleString('en-US', {
                          month: 'long',
                          year: 'numeric',
                        })}
                      </div>

                      <img
                        src={arrow}
                        className="arrow-calendar-right"
                        onClick={increaseMonth}
                      />
                    </div>
                  )}
                />
              </div>

              {screenWidth <= 834 && (
                <>
                  <div className="calendar-page__date-wrap">
                    <span>Date & Time</span>
                    <span>
                      {moment(
                        SelectedDate,
                        'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'
                      ).format('ddd, MMM DD')}
                    </span>
                  </div>
                  <CustomSelection
                    placeholder=""
                    style={{ width: '100%', margin: '12px 0 0 0' }}
                    styledForArrow={
                      screenWidth > 834
                        ? { top: '24px', left: '541px' }
                        : screenWidth > 550
                        ? { top: '25px', left: '394px' }
                        : { top: '25px', left: '311px' }
                    }
                    styledForOptions={{
                      width: '100%',
                      zIndex: '2',
                      top: '59px',
                      height: '205px',
                      overflow: 'hidden',
                    }}
                    options={array}
                    onChange={setHour}
                    value={hour}
                  />
                </>
              )}
              <div className="calendar-page__btn" onClick={() => booknow()}>
                {click ? (
                  <img
                    src={loader}
                    className="loader"
                    style={{ width: '23px', height: '23px' }}
                  />
                ) : (
                  'Book now'
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="calendar-page__title">
                Free 1 hour Strategy Session
              </div>
              <div className="calendar-success">
                <img src={successCalendar} />
                <div className="calendar-success__text">
                  Your strategy session has been successfully booked.
                  <div className="calendar-success__dataText">
                    {moment(SelectedDate).format('DD/MM/YYYY')} at {hour}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="rotate-small-loader">
          <div className="small-loader"></div>
        </div>
      )}
    </>
  );
};
