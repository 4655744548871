import './App.css';
import { Homepage } from './pages/Homepage/Homepage';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Calculator } from './pages/Calculator/Calculator';
import { Layout } from './components/Layout/Layout';
import { Congratulations } from './pages/Congratulations/Congratulations';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { TermsPage } from './pages/TermsPage/TermsPage';
import { Loader } from './components/Loader/Loader';
import { SeventhStep } from './components/StepsContainer/SeventhStep';
import { PageNotFound } from './pages/ErrorPage/PageNotFound';
import { AccessError } from './pages/ErrorPage/AccessError';
import { useEffect, useState } from 'react';
import { personIdentification } from './crud/crud';
import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';

function App() {
  const [user, setUser] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [congratulations, setCongratulations] = useState('');
  const [loading, setLoading] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [steps, setSteps] = useState([
    {
      id: 1,
      title: 'Living Situation',
    },
    {
      id: 2,
      title: 'Assets',
    },
    {
      id: 3,
      title: 'Liabilities',
    },
    {
      id: 4,
      title: 'Income & Super',
    },
  ]);

  useEffect(() => {
    if (location) {
      setLoading('Loading...');
      let res = location.search.split('&');
      const res1 = res.find((elem) => elem.includes('ref'));
      const ref = res1?.split('=')[1];
      personIdentification(ref)
        .then((res) => {
          setUser(res.data.identification.additional_info);
          const array = [
            {
              id: 1,
              title: 'Living Situation',
            },
            {
              id: 2,
              title: 'Assets',
            },
            {
              id: 3,
              title: 'Liabilities',
            },
            {
              id: 4,
              title: 'Income & Super',
            },
          ];
          if (!res.data.identification.additional_info.is_referrer_found) {
            if (array.find((elem) => elem.id === 5)) {
            } else {
              array.push({
                id: 5,
                title: 'Personal Info',
              });
            }
            setSteps(array);
          } else {
            const result = array.filter(
              (elem) => elem.title !== 'Personal Info'
            );
            setSteps(result);
          }
          localStorage.setItem('cow-token', res.data.identification.token);
        })
        .catch((err) => navigate(`/page_not_fount${location?.search}`))
        .finally(() => setLoading(''));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes('result_page')) {
      const res = localStorage.getItem('resultPage');
      if (!res) {
        navigate(`/page_not_fount${location?.search}`);
      }
    }
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader loading={loading} user={user} />
      ) : (
        <div className="wrapper">
          {confirmModal && (
            <ConfirmModal
              cancel={() => setConfirmModal(false)}
              leave={() => {
                navigate(`/home${location?.search}`);
                setConfirmModal(false);
              }}
            />
          )}

          <Routes>
            <Route
              path="*"
              state={{ test: 'test' }}
              element={<Homepage user={user} setCurrentStep={setCurrentStep} />}
            />
            <Route
              path="/home/*"
              state={{ test: 'test' }}
              element={<Homepage user={user} setCurrentStep={setCurrentStep} />}
              exact={true}
            />
            <Route
              path="/result_page/*"
              element={
                <Congratulations
                  congratulations={congratulations}
                  user={user}
                  setLoading={setLoading}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy user={user} />}
            />
            <Route path="/terms" element={<TermsPage user={user} />} />

            <Route
              path="/page_not_fount"
              element={<PageNotFound user={user} />}
            />
            <Route path="/403" element={<AccessError user={user} />} />
            <Route
              path="/calc/*"
              element={
                <Layout
                  user={user}
                  steps={steps}
                  setSteps={setSteps}
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  setCongratulations={setCongratulations}
                  setLoading={setLoading}
                  setConfirmModal={setConfirmModal}
                >
                  {(props) => <Calculator {...props} />}
                </Layout>
              }
            />
          </Routes>
        </div>
      )}
    </>
  );
}

export default App;
