import React from 'react';
import { Slider } from '../Slider/Slider';

import './steps.scss';

export const SecondStep = ({ formData, setFormData }) => {
    return (
        <div className="step-page">
            <div className="step-page__title">Assets</div>
            <div className="step-page__title-text">
                Accurate figures will provide accurate incentives results. Use
                the slider to change the values.
            </div>
            <div className="step-page__block">
                <Slider
                    type="range"
                    name="home_value"
                    min={200000}
                    max={3000000}
                    step={25000}
                    onChange={(e) =>
                        setFormData({ ...formData, home_value: e.target.value })
                    }
                    value={formData.home_value}
                    setFormData={setFormData}
                    formData={formData}
                    title="1. Home Value"
                    buttons
                />

                <Slider
                    type="range"
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_home_renovations: e.target.value,
                        })
                    }
                    title="2. Total Home Renovations"
                    min={0}
                    value={formData.total_home_renovations}
                    max={300000}
                    step={5000}
                />
                <Slider
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_savings: e.target.value,
                        })
                    }
                    value={formData.total_savings}
                    title="3. Total Savings"
                    min={0}
                    max={500000}
                    step={5000}
                />
                <Slider
                    style={{ margin: '0' }}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_shares_crypto: e.target.value,
                        })
                    }
                    value={formData.total_shares_crypto}
                    title="4. Total Shares / Crypto"
                    min={0}
                    max={500000}
                    step={5000}
                />
            </div>
        </div>
    );
};
