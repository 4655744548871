import React, { useState } from 'react';
import { Slider } from '../Slider/Slider';
import { TextField } from '../TextField/TextField';
import mark from '../../images/mark.png';
import InputMask from 'react-input-mask';
import 'react-phone-input-2/lib/style.css';
import au from '../../images/au.png';
import './steps.scss';
import { useEffect } from 'react';

export const SeventhStep = ({
    formData,
    setFormData,
    errors,
    setErrors,
    checked,
    setChecked,
}) => {
    useEffect(() => {
        const data = { ...formData };
        const errorData = { ...errors };
        const emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (data.email && !emailReg.test(String(data.email).toLowerCase())) {
            errorData.email = 'The email is not valid';
        }
        if (data.email && emailReg.test(String(data.email).toLowerCase())) {
            errorData.email = '';
        }
        if (data.firstName) {
            errorData.firstName = '';
        }
        if (data.lastName) {
            errorData.lastName = '';
        }
        if (data.phone) {
            if (data.phone.length !== 10 && touched.phone) {
                errorData.phone = 'Please, enter your mobile phone number';
            } else {
                errorData.phone = '';
            }
        }
        setErrors(errorData);
    }, [formData]);

    const [touched, setTouched] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    });

    useEffect(() => {
        const data = { ...formData };
        const errorData = { ...errors };

        if (!data.firstName && touched.firstName) {
            errorData.firstName = 'This field is required';
        }
        if (!data.lastName && touched.lastName) {
            errorData.lastName = 'This field is required';
        }
        if (!data.email && touched.email) {
            errorData.email = 'This field is required';
        }
        if (!data.phone && touched.phone) {
            errorData.phone = 'This field is required';
        }

        setErrors(errorData);
    }, [touched]);

    const currencyMaskOnlyNumbers = (e) => {
        let value = e.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(?=(\d{3})+(\D))\B/g, ',');
        e.target.value = value;
        return e.target.value;
    };

    return (
        <div className="step-page">
            <div className="step-page__title">Personal Info</div>

            <div className="step-page__block">
                <div className="step-page__subtitle">1. Your name</div>
                <TextField
                    // inputMode="text"
                    type="text"
                    placeholder="First Name"
                    maxLength={240}
                    value={formData.firstName}
                    onChange={(e) =>
                        setFormData({ ...formData, firstName: e.target.value })
                    }
                    errorText={errors.firstName}
                    onBlur={() => setTouched({ ...touched, firstName: true })}
                />
                <TextField
                    // inputMode="text"
                    type="text"
                    placeholder="Last Name"
                    maxLength={240}
                    value={formData.lastName}
                    onChange={(e) =>
                        setFormData({ ...formData, lastName: e.target.value })
                    }
                    errorText={errors.lastName}
                    onBlur={() => setTouched({ ...touched, lastName: true })}
                />
            </div>
            <div className="step-page__block">
                <div className="step-page__subtitle">2. Email address</div>
                <TextField
                    // inputMode="text"
                    placeholder="Email"
                    type="text"
                    maxLength={240}
                    value={formData.email}
                    errorText={errors.email}
                    onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                    }
                    onBlur={() => setTouched({ ...touched, email: true })}
                />
                <div className="step-page__title-text">
                    We respect your privacy and will only use this to send
                    through your savings results.
                </div>
            </div>

            <div className="step-page__block">
                <div className="step-page__subtitle">3. Mobile Number</div>
                <InputMask
                    mask="0499 999 999"
                    value={formData.phone}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            phone: currencyMaskOnlyNumbers(e),
                        });
                    }}
                    onBlur={() => setTouched({ ...touched, phone: true })}
                >
                    {() => (
                        <input
                            className={`text-field__input  ${
                                errors.phone ? 'error' : ''
                            }`}
                            type="tel"
                            placeholder="04"
                            // inputMode="numeric"
                        />
                    )}
                </InputMask>
                <div
                    className="text-field__error"
                    style={{ margin: '12px 0 12px 0' }}
                >
                    {errors.phone}
                </div>
            </div>
            <div className="step-page__block checkbox-container">
                <label className={`label ${checked ? 'checked' : ''}`}>
                    <input
                        type="checkbox"
                        className="checkbox"
                        onChange={() => setChecked((prev) => !prev)}
                        checked={checked}
                    />
                    {checked && <img src={mark} className="checkbox__mark" />}
                </label>
                <div style={{ fontSize: '14px' }}>
                    I understand I’ll receive a sms with my results & quick 5
                    minute obligation-free call explaining how to take advantage
                    of the Government tax incentives that I qualify for.
                </div>
            </div>
        </div>
    );
};
