import React from 'react';
import loader from '../../images/loader.png';
import { Header } from '../Header/Header';
import './loader.scss';

export const Loader = ({ loading, user }) => {
    return (
        <>
            <div className="loader-wrapper">
                <Header loading={true} user={user} />
                <div className="loader__wrap">
                    <img src={loader} className="loader" />
                    <div className="loader__text">{loading}</div>
                </div>
                <div></div>
            </div>
        </>
    );
};
