import React from 'react';
import './ConfirmModal.scss';
import x from '../../images/x.png';

export const ConfirmModal = ({ cancel, leave }) => {
    return (
        <>
            <div className="modal-wrapper"></div>
            <div className="modal">
                <div className="modal__top-row top-row">
                    <div className="top-row__title">Leaving already?</div>
                    <img src={x} className="top-row__close" onClick={cancel} />
                </div>
                <div className="modal__subtitle">
                    Are you sure you don’t want to pay down your home years
                    faster?
                </div>
                <div className="modal__line"></div>
                <div className="modal__btns btns">
                    <div className="btns__cancel modal__btn" onClick={cancel}>
                        Cancel
                    </div>
                    <div className="btns__leave modal__btn" onClick={leave}>
                        Leave
                    </div>
                </div>
            </div>
        </>
    );
};
