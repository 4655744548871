import React, { useState } from 'react';
import { useEffect } from 'react';
import { TextField } from '../TextField/TextField';
import './steps.scss';

export const SixthStep = ({ formData, setFormData, errors, setErrors }) => {
    let count = [];
    for (let i = 1; i <= formData.number_investment_properties; i++) {
        count.push(i);
    }

    useEffect(() => {
        const count = formData.number_investment_properties;
        const data = { ...formData };
        const errorData = { ...errors };
        if (
            +count === 1 ||
            +count === 2 ||
            +count === 3 ||
            +count === 4 ||
            +count === 5
        ) {
            if (
                data.prop[1].value.length === 6 ||
                data.prop[1].value.length === 7 ||
                !data.prop[1].value.length
            ) {
                errorData.prop[1].value = '';
            }
            if (
                (data.prop[1].loan.length >= 4 &&
                    data.prop[1].loan.length <= 7) ||
                !data.prop[1].loan.length ||
                data.prop[1].loan == 0
            ) {
                errorData.prop[1].loan = '';
            }
            if (
                data.prop[1].rent.length === 3 ||
                data.prop[1].rent.length === 4 ||
                !data.prop[1].rent.length ||
                data.prop[1].rent == 0
            ) {
                errorData.prop[1].rent = '';
            }
        }
        if (+count === 2 || +count === 3 || +count === 4 || +count === 5) {
            if (
                data.prop[2].value.length === 6 ||
                data.prop[2].value.length === 7 ||
                !data.prop[2].value.length
            ) {
                errorData.prop[2].value = '';
            }
            if (
                (data.prop[2].loan.length >= 4 &&
                    data.prop[2].loan.length <= 7) ||
                !data.prop[2].loan.length ||
                data.prop[2].loan == 0
            ) {
                errorData.prop[2].loan = '';
            }
            if (
                data.prop[2].rent.length === 3 ||
                data.prop[2].rent.length === 4 ||
                !data.prop[2].rent.length ||
                data.prop[2].rent == 0
            ) {
                errorData.prop[2].rent = '';
            }
        }
        if (+count === 3 || +count === 4 || +count === 5) {
            if (
                data.prop[3].value.length === 6 ||
                data.prop[3].value.length === 7 ||
                !data.prop[3].value.length
            ) {
                errorData.prop[3].value = '';
            }
            if (
                (data.prop[3].loan.length >= 4 &&
                    data.prop[3].loan.length <= 7) ||
                !data.prop[3].loan.length ||
                data.prop[3].loan == 0
            ) {
                errorData.prop[3].loan = '';
            }
            if (
                data.prop[3].rent.length === 3 ||
                data.prop[3].rent.length === 4 ||
                !data.prop[3].rent.length ||
                data.prop[3].rent == 0
            ) {
                errorData.prop[3].rent = '';
            }
        }
        if (+count === 4 || +count === 5) {
            if (
                data.prop[4].value.length === 6 ||
                data.prop[4].value.length === 7 ||
                !data.prop[4].value.length
            ) {
                errorData.prop[4].value = '';
            }
            if (
                (data.prop[4].loan.length >= 4 &&
                    data.prop[4].loan.length <= 7) ||
                !data.prop[4].loan.length ||
                data.prop[4].loan == 0
            ) {
                errorData.prop[4].loan = '';
            }
            if (
                data.prop[4].rent.length === 3 ||
                data.prop[4].rent.length === 4 ||
                !data.prop[4].rent.length ||
                data.prop[4].rent == 0
            ) {
                errorData.prop[4].rent = '';
            }
        }
        if (+count === 5) {
            if (
                data.prop[5].value.length === 6 ||
                data.prop[5].value.length === 7 ||
                !data.prop[5].value.length
            ) {
                errorData.prop[5].value = '';
            }
            if (
                (data.prop[5].loan.length >= 4 &&
                    data.prop[5].loan.length <= 7) ||
                !data.prop[5].loan.length ||
                data.prop[5].loan == 0
            ) {
                errorData.prop[5].loan = '';
            }
            if (
                data.prop[5].rent.length === 3 ||
                data.prop[5].rent.length === 4 ||
                !data.prop[5].rent.length ||
                data.prop[5].rent == 0
            ) {
                errorData.prop[5].rent = '';
            }
        }
        setErrors(errorData);
    }, [formData]);

    const validation = () => {
        const count = formData.number_investment_properties;
        const data = { ...formData };
        const errorData = { ...errors };
        if (
            +count === 1 ||
            +count === 2 ||
            +count === 3 ||
            +count === 4 ||
            +count === 5
        ) {
            if (
                data.prop[1].value.length === 6 ||
                data.prop[1].value.length === 7 ||
                !data.prop[1].value.length
            ) {
                errorData.prop[1].value = '';
            } else {
                errorData.prop[1].value =
                    'Please, double-check your value. It should probably be 6-7 digits';
            }
            if (
                (data.prop[1].loan.length >= 4 &&
                    data.prop[1].loan.length <= 7) ||
                !data.prop[1].loan.length ||
                data.prop[1].loan == 0
            ) {
                errorData.prop[1].loan = '';
            } else {
                errorData.prop[1].loan =
                    'Please, double-check your mortgage. It should probably be 0 or 4-7 digits';
            }
            if (
                data.prop[1].rent.length === 3 ||
                data.prop[1].rent.length === 4 ||
                !data.prop[1].rent.length ||
                data.prop[1].rent == 0
            ) {
                errorData.prop[1].rent = '';
            } else {
                errorData.prop[1].rent =
                    'Please, double-check your weekly rent. It should probably be 0 or 3-4 digits';
            }
        }

        if (+count === 2 || +count === 3 || +count === 4 || +count === 5) {
            if (
                data.prop[2].value.length === 6 ||
                data.prop[2].value.length === 7 ||
                !data.prop[2].value.length
            ) {
                errorData.prop[2].value = '';
            } else {
                errorData.prop[2].value =
                    'Please, double-check your value. It should probably be 6-7 digits';
            }
            if (
                (data.prop[2].loan.length >= 4 &&
                    data.prop[2].loan.length <= 7) ||
                !data.prop[2].loan.length ||
                data.prop[2].loan == 0
            ) {
                errorData.prop[2].loan = '';
            } else {
                errorData.prop[2].loan =
                    'Please, double-check your mortgage. It should probably be 0 or 4-7 digits';
            }
            if (
                data.prop[2].rent.length === 3 ||
                data.prop[2].rent.length === 4 ||
                !data.prop[2].rent.length ||
                data.prop[2].rent == 0
            ) {
                errorData.prop[2].rent = '';
            } else {
                errorData.prop[2].rent =
                    'Please, double-check your weekly rent. It should probably be 0 or 3-4 digits';
            }
        }
        if (+count === 3 || +count === 4 || +count === 5) {
            if (
                data.prop[3].value.length === 6 ||
                data.prop[3].value.length === 7 ||
                !data.prop[3].value.length
            ) {
                errorData.prop[3].value = '';
            } else {
                errorData.prop[3].value =
                    'Please, double-check your value. It should probably be 6-7 digits';
            }
            if (
                (data.prop[3].loan.length >= 4 &&
                    data.prop[3].loan.length <= 7) ||
                !data.prop[3].loan.length ||
                data.prop[3].loan == 0
            ) {
                errorData.prop[3].loan = '';
            } else {
                errorData.prop[3].loan =
                    'Please, double-check your mortgage. It should probably be 0 or 4-7 digits';
            }
            if (
                data.prop[3].rent.length === 3 ||
                data.prop[3].rent.length === 4 ||
                !data.prop[3].rent.length ||
                data.prop[3].rent == 0
            ) {
                errorData.prop[3].rent = '';
            } else {
                errorData.prop[3].rent =
                    'Please, double-check your weekly rent. It should probably be 0 or 3-4 digits';
            }
        }
        if (+count === 4 || +count === 5) {
            if (
                data.prop[4].value.length === 6 ||
                data.prop[4].value.length === 7 ||
                !data.prop[4].value.length
            ) {
                errorData.prop[4].value = '';
            } else {
                errorData.prop[4].value =
                    'Please, double-check your value. It should probably be 6-7 digits';
            }
            if (
                (data.prop[4].loan.length >= 4 &&
                    data.prop[4].loan.length <= 7) ||
                !data.prop[4].loan.length ||
                data.prop[4].loan == 0
            ) {
                errorData.prop[4].loan = '';
            } else {
                errorData.prop[4].loan =
                    'Please, double-check your mortgage. It should probably be 0 or 4-7 digits';
            }
            if (
                data.prop[4].rent.length === 3 ||
                data.prop[4].rent.length === 4 ||
                !data.prop[4].rent.length ||
                data.prop[4].rent == 0
            ) {
                errorData.prop[4].rent = '';
            } else {
                errorData.prop[4].rent =
                    'Please, double-check your weekly rent. It should probably be 0 or 3-4 digits';
            }
        }
        if (+count === 5) {
            if (
                data.prop[5].value.length === 6 ||
                data.prop[5].value.length === 7 ||
                !data.prop[5].value.length
            ) {
                errorData.prop[5].value = '';
            } else {
                errorData.prop[5].value =
                    'Please, double-check your value. It should probably be 6-7 digits';
            }
            if (
                (data.prop[5].loan.length >= 4 &&
                    data.prop[5].loan.length <= 7) ||
                !data.prop[5].loan.length ||
                data.prop[5].loan == 0
            ) {
                errorData.prop[5].loan = '';
            } else {
                errorData.prop[5].loan =
                    'Please, double-check your mortgage. It should probably be 0 or 4-7 digits';
            }
            if (
                data.prop[5].rent.length === 3 ||
                data.prop[5].rent.length === 4 ||
                !data.prop[5].rent.length ||
                data.prop[5].rent == 0
            ) {
                errorData.prop[5].rent = '';
            } else {
                errorData.prop[5].rent =
                    'Please, double-check your weekly rent. It should probably be 0 or 3-4 digits';
            }
        }
        setErrors(errorData);
    };

    return (
        <div className="step-page">
            <div className="step-page__title">Investment Properties</div>
            <div className="step-page__prop-wrap">
                {count.map((elem) => {
                    return (
                        <div className="step-page__prop" key={elem}>
                            <div className="step-page__subtitle">
                                Investment Property {elem}
                            </div>
                            <TextField
                                inputMode="numeric"
                                className="text-field-prop"
                                placeholder="Value"
                                dollar
                                value={formData.prop[elem].value}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        prop: {
                                            ...formData.prop,
                                            [elem]: {
                                                ...formData.prop[elem],
                                                value: e.target.value,
                                            },
                                        },
                                    });
                                }}
                                onBlur={() => validation()}
                                error={errors.prop[elem].value}
                                maxLength={13}
                            />
                            {errors.prop[elem].value && (
                                <div className="step-page__error">
                                    {errors.prop[elem].value}
                                </div>
                            )}
                            <TextField
                                inputMode="numeric"
                                className="text-field-prop"
                                value={formData.prop[elem].loan}
                                placeholder="Mortgage"
                                maxLength={13}
                                dollar
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        prop: {
                                            ...formData.prop,
                                            [elem]: {
                                                ...formData.prop[elem],
                                                loan: e.target.value,
                                            },
                                        },
                                    });
                                }}
                                onBlur={() => validation()}
                                error={errors.prop[elem].loan}
                            />
                            {errors.prop[elem].loan && (
                                <div className="step-page__error">
                                    {errors.prop[elem].loan}
                                </div>
                            )}
                            <TextField
                                inputMode="numeric"
                                className="text-field-prop"
                                value={formData.prop[elem].rent}
                                placeholder="Rent (Weekly)"
                                maxLength={13}
                                dollar
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        prop: {
                                            ...formData.prop,
                                            [elem]: {
                                                ...formData.prop[elem],
                                                rent: e.target.value,
                                            },
                                        },
                                    });
                                }}
                                onBlur={() => validation()}
                                error={errors.prop[elem].rent}
                            />
                            {errors.prop[elem].rent && (
                                <div className="step-page__error">
                                    {errors.prop[elem].rent}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
