import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import ReactSlider from 'react-slider';
import run from '../../images/run.png';
import arrow from '../../images/arrow-bottom.png';
import './slider.scss';

export const Slider = ({
    title,
    name,
    min,
    max,
    step,
    onChange,
    value,
    setFormData,
    formData,
    buttons,
    style,
}) => {
    const left = () => {
        let a = min;
        let b = max;
        let ziro = b - a;
        let one = ziro / 100;
        const res = (value - min) / one;
        let res2 = res;
        return `${res2.toFixed()}% `;
    };
    return (
        <div className="slider" style={style}>
            <div className="slider__values">
                <div className="slider__title">{title}</div>
                <div
                    className="slider__value"
                    style={
                        buttons ? { position: 'absolute', right: '-14px' } : {}
                    }
                >
                    {buttons && (
                        <div
                            className="slider__button"
                            onClick={() => {
                                if (formData.home_value <= 200000) {
                                    return;
                                } else {
                                    setFormData({
                                        ...formData,
                                        home_value:
                                            +formData.home_value - +step,
                                    });
                                }
                            }}
                        >
                            -
                        </div>
                    )}
                    <div className="slider__dollar">$</div>
                    <div className="slider__number">
                        {value
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    {buttons && (
                        <div
                            className="slider__button"
                            onClick={() => {
                                if (formData.home_value >= 3000000) {
                                    return;
                                } else {
                                    setFormData({
                                        ...formData,
                                        home_value:
                                            +formData.home_value + +step,
                                    });
                                }
                            }}
                        >
                            +
                        </div>
                    )}
                </div>
            </div>

            <div className="slider__line" style={{ width: left() }}></div>
            <div className="slider__line-grey" style={{ width: '100%' }}></div>

            <input
                type="range"
                name={name}
                onChange={(e) => {
                    onChange(e);
                }}
                min={min}
                max={max}
                step={step}
                value={value}
                className="slider__range"
            />

            <div className="slider__values" style={{ margin: '10px 0 0 0' }}>
                <div className="slider__item">
                    ${min?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
                <div className="slider__item">
                    ${max?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
            </div>
        </div>
    );
};
