import React, { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import mark from '../../images/mark.png';

import './layout.scss';

export const Layout = ({
    children,
    user,
    steps,
    setSteps,
    currentStep,
    setCurrentStep,
    setCongratulations,
    setLoading,
    setConfirmModal,
}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    addEventListener('resize', (event) => {
        setScreenWidth(window.innerWidth);
    });

    const heightSteps = () => {
        if (currentStep === 1) {
            return '0px';
        }
        if (currentStep === 2) {
            return '84px';
        }
        if (currentStep === 3) {
            return '158px';
        }
        if (currentStep === 4) {
            return '232px';
        }
        if (currentStep === 5) {
            return '304px';
        }
        if (currentStep === 6) {
            return '378px';
        }
        if (currentStep === 7) {
            return '453px';
        }
    };

    const widthSteps = () => {
        const length = steps.length;
        if (length === 4) {
            if (currentStep === 1) {
                return '0%';
            }
            if (currentStep === 2) {
                return '34%';
            }
            if (currentStep === 3) {
                return '66%';
            }
            if (currentStep === 4) {
                return '99%';
            }
        }
        if (length === 5) {
            if (currentStep === 1) {
                return '0%';
            }
            if (currentStep === 2) {
                return '25%';
            }
            if (currentStep === 3) {
                return '50%';
            }
            if (currentStep === 4) {
                return '74%';
            }
            if (currentStep === 5 || currentStep === 6 || currentStep === 7) {
                return '99%';
            }
        }
        if (length === 6) {
            if (currentStep === 1) {
                return '0%';
            }
            if (currentStep === 2) {
                return '21%';
            }
            if (currentStep === 3) {
                return '41%';
            }
            if (currentStep === 4) {
                return '60%';
            }
            if (currentStep === 5) {
                return '79%';
            }
            if (currentStep === 6) {
                return '99%';
            }
        }
        if (length === 7) {
            if (currentStep === 1) {
                return '0%';
            }
            if (currentStep === 2) {
                return '19%';
            }
            if (currentStep === 3) {
                return '35%';
            }
            if (currentStep === 4) {
                return '50%';
            }
            if (currentStep === 5) {
                return '65%';
            }
            if (currentStep === 6) {
                return '82%';
            }
            if (currentStep === 7) {
                return '99%';
            }
        }
    };

    return (
        <div className="layout">
            <Header user={user} setConfirmModal={setConfirmModal} />
            <div className="layout__wrapper">
                <div className="layout__container container">
                    {screenWidth > 900 ? (
                        <div className="layout-nav">
                            <div className="layout__title">
                                Steps for completing
                            </div>
                            <div className="layout__subtitle">
                                Check if you qualify to pay down your home in
                                under 10 years, by leveraging Investment
                                Property & Government Tax Incentives that help
                                you save & redirect up to 50% of your tax
                                dollars back into your mortgage.
                            </div>
                            <div className="layout__steps">
                                <div className="layout__line-steps"></div>
                                <div
                                    className="layout__line-steps-active"
                                    style={{ height: heightSteps() }}
                                ></div>
                                {steps.map((elem) => {
                                    return (
                                        <div
                                            className="layout__step step"
                                            key={elem.title}
                                        >
                                            <div
                                                className={`step__circel ${
                                                    +currentStep === +elem.id
                                                        ? 'active'
                                                        : ''
                                                } ${
                                                    +currentStep > +elem.id
                                                        ? 'done'
                                                        : ''
                                                }`}
                                            >
                                                {+currentStep > +elem.id && (
                                                    <img
                                                        src={mark}
                                                        className="layout__mark"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className={`step__title ${
                                                    elem.status
                                                } ${
                                                    +currentStep === +elem.id
                                                        ? 'active'
                                                        : ''
                                                } ${
                                                    +currentStep > +elem.id
                                                        ? 'done'
                                                        : ''
                                                }`}
                                            >
                                                <div>
                                                    <div className="step__extra">
                                                        {elem.title ===
                                                            'Partners Income & Super' ||
                                                        elem.title ===
                                                            'Investment Properties'
                                                            ? 'Extra Question'
                                                            : ''}
                                                    </div>
                                                    {elem.title}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="layout-nav">
                            <div className="layout__steps">
                                <div className="layout__line-steps"></div>
                                <div
                                    className="layout__line-steps-active"
                                    style={{ width: widthSteps() }}
                                ></div>
                                <div className="layout__steps-wrap">
                                    {steps.map((elem) => {
                                        return (
                                            <div
                                                className="layout__step step"
                                                key={elem.title}
                                            >
                                                <div
                                                    className={`step__circel ${
                                                        +currentStep ===
                                                        +elem.id
                                                            ? 'active'
                                                            : ''
                                                    } ${
                                                        +currentStep > +elem.id
                                                            ? 'done'
                                                            : ''
                                                    }`}
                                                >
                                                    {+currentStep >
                                                        +elem.id && (
                                                        <img
                                                            src={mark}
                                                            className="layout__mark"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="layout__steptitle-wrap wrap">
                                <div className="wrap__title">
                                    Step {currentStep}/{steps.length}
                                </div>
                                <div className="wrap__value">
                                    {
                                        steps.find(
                                            (elem) => +elem.id === +currentStep
                                        ).title
                                    }
                                </div>
                            </div>
                            {screenWidth <= 600 && (
                                <div className="layout__line"></div>
                            )}
                        </div>
                    )}

                    {children({
                        setCurrentStep,
                        currentStep,
                        setSteps,
                        steps,
                        user,
                        setCongratulations,
                        setLoading,
                    })}
                </div>
            </div>
        </div>
    );
};
