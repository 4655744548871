import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '../../components/Footer/Footer';
import { Header } from '../../components/Header/Header';
import '../PrivacyPolicy/PrivacyPolicy.scss';

export const TermsPage = ({ user }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const goBack = () => {
        let res = '';
        if (location?.state?.from === '/privacy-policy') {
            res = 'Privacy Policy';
        }
        if (location?.state?.from === '/home') {
            res = 'Hero Page';
        }
        if (location?.state?.from.includes('/result_page')) {
            res = 'Result Page';
        }
        if (location?.state?.from === '/terms') {
            res = 'Terms of service';
        }
        return res;
    };

    return (
        <>
            <Header user={user} />
            <div className="privacy">
                <div className="container">
                    <div className="privacy__wrap">
                        <div className="privacy__nav">
                            <div
                                onClick={() => navigate(-1)}
                                style={{ cursor: 'pointer' }}
                            >
                                {goBack()}
                            </div>
                            / <div>Terms of Service</div>
                        </div>
                        <div className="privacy__title">Terms of Service</div>
                        <div className="privacy__main">
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    1. Terms
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    Your privacy is important to us. It is
                                    National Wealth Advisory Pty Ltd's policy to
                                    respect your privacy regarding any
                                    information we may collect from you across
                                    our websites,{' '}
                                    <a
                                        href="https://nationalwealthadvisory.com.au"
                                        target="_blanc"
                                    >
                                        https://nationalwealthadvisory.com.au
                                    </a>
                                    , and other sites we own and operate.
                                </p>
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    2. Use License
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    1. Permission is granted to temporarily
                                    download one copy of the materials
                                    (information or software) on National Wealth
                                    Advisory website for personal,
                                    non-commercial transitory viewing only. This
                                    is the grant of a license, not a transfer of
                                    title, and under this license you may not:{' '}
                                </p>
                                <ul>
                                    <li> modify or copy the materials;</li>
                                    <li>
                                        use the materials for any commercial
                                        purpose, or for any public display
                                        (commercial or non-commercial);
                                    </li>
                                    <li>
                                        attempt to decompile or reverse engineer
                                        any software contained on National
                                        Wealth Advisory website;
                                    </li>
                                    <li>
                                        {' '}
                                        remove any copyright or other
                                        proprietary notations from the
                                        materials; or
                                    </li>
                                    <li>
                                        {' '}
                                        transfer the materials to another person
                                        or "mirror" the materials on any other
                                        server.
                                    </li>
                                </ul>{' '}
                                2.This license shall automatically terminate if
                                you violate any of these restrictions and may be
                                terminated by National Wealth Advisory at any
                                time. Upon terminating your viewing of these
                                materials or upon the termination of this
                                license, you must destroy any downloaded
                                materials in your possession whether in
                                electronic or printed format.
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    3. Disclaimer
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    <p style={{ margin: '4px 0 0 0' }}>
                                        1. The materials on National Wealth
                                        Advisory website are provided on an 'as
                                        is' basis. National Wealth Advisory
                                        makes no warranties, expressed or
                                        implied, and hereby disclaims and
                                        negates all other warranties including,
                                        without limitation, implied warranties
                                        or conditions of merchantability,
                                        fitness for a particular purpose, or
                                        non-infringement of intellectual
                                        property or other violation of rights.{' '}
                                    </p>{' '}
                                    <p>
                                        2. Further, National Wealth Advisory
                                        does not warrant or make any
                                        representations concerning the accuracy,
                                        likely results, or reliability of the
                                        use of the materials on its website or
                                        otherwise relating to such materials or
                                        on any sites linked to this site.
                                    </p>
                                </p>
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    4. Limitations
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    In no event shall National Wealth Advisory
                                    or its suppliers be liable for any damages
                                    (including, without limitation, damages for
                                    loss of data or profit, or due to business
                                    interruption) arising out of the use or
                                    inability to use the materials on National
                                    Wealth Advisory website, even if National
                                    Wealth Advisory or a National Wealth
                                    Advisory authorized representative has been
                                    notified orally or in writing of the
                                    possibility of such damage. Because some
                                    jurisdictions do not allow limitations on
                                    implied warranties, or limitations of
                                    liability for consequential or incidental
                                    damages, these limitations may not apply to
                                    you.
                                </p>
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    5. Accuracy of materials
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    The materials appearing on National Wealth
                                    Advisory website could include technical,
                                    typographical, or photographic errors.
                                    National Wealth Advisory does not warrant
                                    that any of the materials on its website are
                                    accurate, complete or current. National
                                    Wealth Advisory may make changes to the
                                    materials contained on its website at any
                                    time without notice. However National Wealth
                                    Advisory does not make any commitment to
                                    update the materials.
                                </p>
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    6. Links
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    National Wealth Advisory has not reviewed
                                    all of the sites linked to its website and
                                    is not responsible for the contents of any
                                    such linked site. The inclusion of any link
                                    does not imply endorsement by National
                                    Wealth Advisory of the site. Use of any such
                                    linked website is at the user's own risk.
                                </p>
                            </div>
                            <div className="privacy__block">
                                <div className="privacy__subtitle">
                                    7. Modifications
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    National Wealth Advisory may revise these
                                    terms of service for its website at any time
                                    without notice. By using this website you
                                    are agreeing to be bound by the then current
                                    version of these terms of service.
                                </p>
                            </div>
                            <div
                                className="privacy__block"
                                style={{ margin: '0 0 0 0' }}
                            >
                                <div className="privacy__subtitle">
                                    8. Governing Law
                                </div>
                                <p style={{ margin: '4px 0 0 0' }}>
                                    These terms and conditions are governed by
                                    and construed in accordance with the laws of
                                    Queensland, Australia and you irrevocably
                                    submit to the exclusive jurisdiction of the
                                    courts in that State or location.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer user={user} />
        </>
    );
};
