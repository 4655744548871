import React from 'react';
import { useEffect, useState } from 'react';
import { Slider } from '../Slider/Slider';
import { TextField } from '../TextField/TextField';
import './steps.scss';

export const FourthStep = ({ formData, setFormData, errors, setErrors }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    addEventListener('resize', (event) => {
        setScreenWidth(window.innerWidth);
    });
    useEffect(() => {
        if (formData.age_1) {
            if (formData.age_1 < 18) {
                setErrors({
                    ...errors,
                    age_1: 'You should be at least 18 years old to use our service',
                });
            } else {
                setErrors({
                    ...errors,
                    age_1: '',
                });
            }
        } else {
            setErrors({
                ...errors,
                age_1: '',
            });
        }
    }, [formData.age_1]);

    return (
        <div className="step-page">
            <div className="step-page__title">Income & Super</div>

            <div
                className="step-page__block"
                style={screenWidth <= 600 ? { padding: '16px 0 0 0' } : {}}
            >
                <Slider
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            annual_income_1: e.target.value,
                        })
                    }
                    value={formData.annual_income_1}
                    title={
                        screenWidth > 500 ? (
                            <div>1. Your Annual Income (Before Tax)</div>
                        ) : (
                            <div>
                                1. Your Annual Income <br />
                                (Before Tax)
                            </div>
                        )
                    }
                    min={0}
                    max={400000}
                    step={5000}
                />

                <Slider
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            super_balance_1: e.target.value,
                        })
                    }
                    value={formData.super_balance_1}
                    title="2. Your Super Balance"
                    min={0}
                    max={500000}
                    step={5000}
                />
                <div
                    className="step-page__subtitle"
                    style={{ fontSize: '14px' }}
                >
                    3. Your Age
                </div>
                <div style={{ minHeight: '60px' }}>
                    <TextField
                        inputMode="numeric"
                        className="step-page__field"
                        onChange={(e) =>
                            setFormData({ ...formData, age_1: e.target.value })
                        }
                        value={formData.age_1}
                        errorText={errors.age_1}
                        maxLength={2}
                    />
                </div>
            </div>
        </div>
    );
};
