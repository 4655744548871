import React from 'react';
import './customcheckbox.scss';

export const CustomCheckbox = ({ title, checked, onChange, name }) => {
    return (
        <div
            className={`checkbox ${checked ? 'checked' : ''}`}
            onClick={() => onChange(name)}
        >
            <label className={`checkbox__label ${checked ? 'checked' : ''}`}>
                <input
                    type="checkbox"
                    className="checkbox__checkbox"
                    checked={checked}
                    onChange={() => onChange(name)}
                />
            </label>

            <div className="checkbox__title">{title}</div>
        </div>
    );
};
