import React, { useEffect, useState } from 'react';
import { FirstStep } from '../../components/StepsContainer/FirstStep';
import { SecondStep } from '../../components/StepsContainer/SecondStep';
import { ThirdStep } from '../../components/StepsContainer/ThirdStep';
import { FourthStep } from '../../components/StepsContainer/FourthStep';
import { FifthStep } from '../../components/StepsContainer/FifthStep';
import './calculator.scss';
import loader from '../../images/small-loader.png';
import { SixthStep } from '../../components/StepsContainer/SixthStep';
import { useNavigate } from 'react-router-dom';
import { SeventhStep } from '../../components/StepsContainer/SeventhStep';
import { stepsFunc } from './stepsFunc';
import { PostFormData } from '../../crud/crud';

export const Calculator = ({
  setCurrentStep,
  currentStep,
  setSteps,
  steps,
  user,
  setCongratulations,
  setLoading,
}) => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [click, setClick] = useState(false);
  const [clickOnPrev, setClickOnPrev] = useState(false);
  const [formData, setFormData] = useState({
    marital_status: 'married/de_facto',
    dependant: 0,
    number_investment_properties: 0,

    home_value: 200000,
    total_home_renovations: 0,
    total_savings: 0,
    total_shares_crypto: 0,

    total_home_mortgage: 0,
    total_personal_vehicle_loans: 0,
    total_limit_combined_credit_cards: 0,

    annual_income_1: 0,
    super_balance_1: 0,
    age_1: '',

    annual_income_2: 0,
    super_balance_2: 0,
    age_2: '',

    prop: {
      1: { value: '', loan: '', rent: '' },
      2: { value: '', loan: '', rent: '' },
      3: { value: '', loan: '', rent: '' },
      4: { value: '', loan: '', rent: '' },
      5: { value: '', loan: '', rent: '' },
    },

    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [errors, setErrors] = useState({
    age_1: '',
    email: '',
    prop: {
      1: { value: '', loan: '', rent: '' },
      2: { value: '', loan: '', rent: '' },
      3: { value: '', loan: '', rent: '' },
      4: { value: '', loan: '', rent: '' },
      5: { value: '', loan: '', rent: '' },
    },
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  document.title = 'Calculating Incentives';
  const [utmParams, setUtmParams] = useState();
  const [isCheckAvailable, setIsCheckAvailable] = useState(true);
  const [clickOnCheck, setClickOnCheck] = useState(false);

  useEffect(() => {
    let isValid = true;
    for (let prop in errors.prop) {
      if (errors.prop[prop].value) {
        isValid = false;
      }
      if (errors.prop[prop].loan) {
        isValid = false;
      }
      if (errors.prop[prop].rent) {
        isValid = false;
      }
    }
    setIsCheckAvailable(isValid);
  }, [errors]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (localStorage.getItem('resultPage')) {
      localStorage.removeItem('resultPage');
    }
  }, []);

  useEffect(() => {
    if (!location.search) return;
    const paramsString = location.search.split('?')[1].split('&');
    const index = paramsString.findIndex((elem) => elem.includes('ref'));
    if (index > 0) {
      paramsString.splice(index, 1);
    }
    const data = {};
    paramsString.forEach((elem) => {
      if (elem.includes('utm')) {
        const element = elem.split('=');
        if (!data[element[0]]) {
          data[element[0]] = element[1];
        }
      }
    });
    setUtmParams(data);
  }, [location]);

  useEffect(() => {
    if (clickOnCheck) {
      setTimeout(() => {
        setLoading('Analysing equity...');
      }, 0);
      setTimeout(() => {
        setLoading('Analysing servicing...');
      }, 1000);
      setTimeout(() => {
        setLoading('Analysing superannuation...');
      }, 2000);
    }
  }, [clickOnCheck]);

  const CheckIncentives = () => {
    let isValid = true;
    if (currentStep === 4) {
      if (!formData.age_1) {
        setErrors({ ...errors, age_1: 'This field is required' });
        isValid = false;
      } else {
        if (formData.age_1 < 18) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }
    if (
      steps.find((elem) => +elem.id === currentStep).title ===
      'Partners Income & Super'
    ) {
      if (!formData.age_2) {
        setErrors({ ...errors, age_2: 'This field is required' });
        isValid = false;
      } else {
        if (formData.age_2 < 18) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }
    if (
      steps.find((elem) => +elem.id === currentStep).title ===
      'Investment Properties'
    ) {
      const count = formData.number_investment_properties;
      const data = { ...formData };
      const errorData = { ...errors };
      if (
        +count === 1 ||
        +count === 2 ||
        +count === 3 ||
        +count === 4 ||
        +count === 5
      ) {
        if (!data.prop[1].value) {
          isValid = false;
          errorData.prop[1].value = 'This field is required';
        }
        if (!data.prop[1].loan) {
          isValid = false;
          errorData.prop[1].loan = 'This field is required';
        }
        if (!data.prop[1].rent) {
          isValid = false;
          errorData.prop[1].rent = 'This field is required';
        }
      }

      if (+count === 2 || +count === 3 || +count === 4 || +count === 5) {
        if (!data.prop[2].value) {
          isValid = false;
          errorData.prop[2].value = 'This field is required';
        }
        if (!data.prop[2].loan) {
          isValid = false;
          errorData.prop[2].loan = 'This field is required';
        }
        if (!data.prop[2].rent) {
          isValid = false;
          errorData.prop[2].rent = 'This field is required';
        }
      }
      if (+count === 3 || +count === 4 || +count === 5) {
        if (!data.prop[3].value) {
          isValid = false;
          errorData.prop[3].value = 'This field is required';
        }
        if (!data.prop[3].loan) {
          isValid = false;
          errorData.prop[3].loan = 'This field is required';
        }
        if (!data.prop[3].rent) {
          isValid = false;
          errorData.prop[3].rent = 'This field is required';
        }
      }
      if (+count === 4 || +count === 5) {
        if (!data.prop[4].value) {
          isValid = false;
          errorData.prop[4].value = 'This field is required';
        }
        if (!data.prop[4].loan) {
          isValid = false;
          errorData.prop[4].loan = 'This field is required';
        }
        if (!data.prop[4].rent) {
          isValid = false;
          errorData.prop[4].rent = 'This field is required';
        }
      }
      if (+count === 5) {
        if (!data.prop[5].value) {
          isValid = false;
          errorData.prop[5].value = 'This field is required';
        }
        if (!data.prop[5].loan) {
          isValid = false;
          errorData.prop[5].loan = 'This field is required';
        }
        if (!data.prop[5].rent) {
          isValid = false;
          errorData.prop[5].rent = 'This field is required';
        }
      }
      setErrors(errorData);
    }
    if (
      steps.find((elem) => +elem.id === currentStep).title === 'Personal Info'
    ) {
      const data = { ...formData };
      const errorData = { ...errors };
      if (!checked) {
        isValid = false;
      }
      if (!data.firstName) {
        isValid = false;
        errorData.firstName = 'This field is required';
      }
      if (!data.lastName) {
        isValid = false;
        errorData.lastName = 'This field is required';
      }
      if (!data.email) {
        isValid = false;
        errorData.email = 'This field is required';
      }
      if (!data.phone) {
        isValid = false;
        errorData.phone = 'This field is required';
      }
      if (errors.phone) {
        isValid = false;
      }
      setErrors(errorData);
    }
    if (isValid) {
      setClick(true);
      const data = {
        marital_status: formData.marital_status,
        dependant: +formData.dependant,
        number_investment_properties: +formData.number_investment_properties,
        home_value: formData.home_value,
        total_home_renovations: formData.total_home_renovations,
        total_savings: formData.total_savings,
        total_shares_crypto: formData.total_shares_crypto,
        total_home_mortgage: formData.total_home_mortgage,
        total_personal_vehicle_loans: formData.total_personal_vehicle_loans,
        total_limit_combined_credit_cards:
          formData.total_limit_combined_credit_cards,
        annual_income_1: formData.annual_income_1,
        super_balance_1: formData.super_balance_1,
        age_1: formData.age_1,
      };
      if (
        formData.marital_status === 'married/de_facto' ||
        formData.marital_status === 'engaged'
      ) {
        data.annual_income_2 = formData.annual_income_2;
        data.super_balance_2 = formData.super_balance_2;
        data.age_2 = formData.age_2;
      }
      if (!user.is_referrer_found) {
        data.person = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone: formData.phone,
        };
      }
      if (utmParams) {
        data.utm_params = utmParams;
      }

      data.home_value_1 = +formData.prop[1].value.split(',').join('') || 0;

      data.mortgage_1 = +formData.prop[1].loan.split(',').join('') || 0;
      data.rent_weekly_1 = +formData.prop[1].rent.split(',').join('') || 0;

      data.home_value_2 = +formData.prop[2].value.split(',').join('') || 0;
      data.mortgage_2 = +formData.prop[2].loan.split(',').join('') || 0;
      data.rent_weekly_2 = +formData.prop[2].rent.split(',').join('') || 0;

      data.home_value_3 = +formData.prop[3].value.split(',').join('') || 0;
      data.mortgage_3 = +formData.prop[3].loan.split(',').join('') || 0;
      data.rent_weekly_3 = +formData.prop[3].rent.split(',').join('') || 0;

      data.home_value_4 = +formData.prop[4].value.split(',').join('') || 0;
      data.mortgage_4 = +formData.prop[4].loan.split(',').join('') || 0;
      data.rent_weekly_4 = +formData.prop[4].rent.split(',').join('') || 0;

      data.home_value_5 = +formData.prop[5].value.split(',').join('') || 0;
      data.mortgage_5 = +formData.prop[5].loan.split(',').join('') || 0;
      data.rent_weekly_5 = +formData.prop[5].rent.split(',').join('') || 0;
      setClickOnCheck(true);
      PostFormData(data)
        .then((res) => {
          localStorage.setItem('resultPage', JSON.stringify(res.data.record));
          setCongratulations(res.data.record);
          if (res.data.record.investment_strategy.page === '2A') {
            navigate(`/result_page/personal${location?.search}`);
          }
          if (res.data.record.investment_strategy.page === '2B') {
            navigate(`/result_page/super${location?.search}`);
          }
          if (res.data.record.investment_strategy.page === '2C') {
            navigate(`/result_page/personalandsuper${location?.search}`);
          }
          if (res.data.record.investment_strategy.page === '2D') {
            navigate(`/result_page/wealthcreation${location?.search}`);
          }
          if (res.data.record.investment_strategy.page === '2E') {
            navigate(`/result_page/sorry${location?.search}`);
          }
        })
        .catch(() => {})
        .finally(() => {
          setClickOnCheck(false);
          setClick(false);
        });
    }
  };

  useEffect(() => {
    if (currentStep >= 2) {
      stepsFunc(formData, user, setSteps, currentStep);
    }
  }, [currentStep]);

  const nextStep = () => {
    let isValid = true;
    const array = [];
    for (let i = 0; i < steps.length; i++) {
      array.push(steps[i].id);
    }
    if (currentStep === 1) {
      stepsFunc(formData, user, setSteps, currentStep);
    }
    if (currentStep === 4) {
      if (!formData.age_1) {
        setErrors({ ...errors, age_1: 'This field is required' });
        isValid = false;
      } else {
        if (formData.age_1 < 18) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }
    if (
      steps.find((elem) => +elem.id === currentStep).title ===
      'Partners Income & Super'
    ) {
      if (!formData.age_2) {
        setErrors({ ...errors, age_2: 'This field is required' });
        isValid = false;
      } else {
        if (formData.age_2 < 18) {
          isValid = false;
        } else {
          isValid = true;
        }
      }
    }
    if (
      steps.find((elem) => +elem.id === currentStep).title ===
      'Investment Properties'
    ) {
      const count = formData.number_investment_properties;
      const data = { ...formData };
      const errorData = { ...errors };
      if (
        +count === 1 ||
        +count === 2 ||
        +count === 3 ||
        +count === 4 ||
        +count === 5
      ) {
        if (!data.prop[1].value) {
          isValid = false;
          errorData.prop[1].value = 'This field is required';
        }
        if (!data.prop[1].loan) {
          isValid = false;
          errorData.prop[1].loan = 'This field is required';
        }
        if (!data.prop[1].rent) {
          isValid = false;
          errorData.prop[1].rent = 'This field is required';
        }
      }

      if (+count === 2 || +count === 3 || +count === 4 || +count === 5) {
        if (!data.prop[2].value) {
          isValid = false;
          errorData.prop[2].value = 'This field is required';
        }
        if (!data.prop[2].loan) {
          isValid = false;
          errorData.prop[2].loan = 'This field is required';
        }
        if (!data.prop[2].rent) {
          isValid = false;
          errorData.prop[2].rent = 'This field is required';
        }
      }
      if (+count === 3 || +count === 4 || +count === 5) {
        if (!data.prop[3].value) {
          isValid = false;
          errorData.prop[3].value = 'This field is required';
        }
        if (!data.prop[3].loan) {
          isValid = false;
          errorData.prop[3].loan = 'This field is required';
        }
        if (!data.prop[3].rent) {
          isValid = false;
          errorData.prop[3].rent = 'This field is required';
        }
      }
      if (+count === 4 || +count === 5) {
        if (!data.prop[4].value) {
          isValid = false;
          errorData.prop[4].value = 'This field is required';
        }
        if (!data.prop[4].loan) {
          isValid = false;
          errorData.prop[4].loan = 'This field is required';
        }
        if (!data.prop[4].rent) {
          isValid = false;
          errorData.prop[4].rent = 'This field is required';
        }
      }
      if (+count === 5) {
        if (!data.prop[5].value) {
          isValid = false;
          errorData.prop[5].value = 'This field is required';
        }
        if (!data.prop[5].loan) {
          isValid = false;
          errorData.prop[5].loan = 'This field is required';
        }
        if (!data.prop[5].rent) {
          isValid = false;
          errorData.prop[5].rent = 'This field is required';
        }
      }
      setErrors(errorData);
    }
    if (isValid) {
      setClick(true);
      setTimeout(() => {
        setCurrentStep((prev) => {
          if (prev + 1 <= steps.length) {
            return prev + 1;
          }
          if (prev + 1 > steps.length) {
            return prev;
          }
        });
        setClick(false);
      }, 500);
    }
  };

  const prevStep = () => {
    setClickOnPrev(true);
    setTimeout(() => {
      setCurrentStep((prev) => {
        if (prev <= 1) {
          return prev;
        }
        if (prev > 1) {
          return prev - 1;
        }
      });
      setClickOnPrev(false);
    }, 500);
  };

  return (
    <div className="calc">
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Living Situation' && (
        <FirstStep formData={formData} setFormData={setFormData} />
      )}
      {steps.find((elem) => +elem.id === currentStep).title === 'Assets' && (
        <SecondStep formData={formData} setFormData={setFormData} />
      )}
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Liabilities' && (
        <ThirdStep formData={formData} setFormData={setFormData} />
      )}
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Income & Super' && (
        <FourthStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Partners Income & Super' && (
        <FifthStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Investment Properties' && (
        <SixthStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
        />
      )}
      {steps.find((elem) => +elem.id === currentStep).title ===
        'Personal Info' && (
        <SeventhStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          checked={checked}
          setChecked={setChecked}
        />
      )}

      <div className="calc__btn-wrap">
        {currentStep === 1 ? (
          <div></div>
        ) : (
          <div
            style={clickOnPrev ? { background: '#F0F4F7' } : { background: '' }}
            className={`calc__prev ${
              steps.length !== currentStep ? '' : 'withCheck'
            }`}
            onClick={() => prevStep()}
          >
            {clickOnPrev ? (
              <img
                src={loader}
                className="loader"
                style={{ width: '23px', height: '23px' }}
              />
            ) : (
              ' Previous'
            )}
          </div>
        )}

        {steps.length === currentStep && (
          <div
            className={`calc__check ${
              steps.find((elem) => +elem.id === currentStep).title ===
                'Personal Info' && !checked
                ? 'disabled'
                : ''
            } ${
              !isCheckAvailable &&
              steps.find((elem) => +elem.id === currentStep).title ===
                'Investment Properties'
                ? 'disabled'
                : ''
            }`}
            style={click ? { background: '#F4D298' } : { background: '' }}
            onClick={() => {
              if (
                steps.find((elem) => +elem.id === currentStep).title ===
                'Personal Info'
              ) {
                if (checked) {
                  CheckIncentives();
                }
              } else {
                if (isCheckAvailable) {
                  CheckIncentives();
                }
              }
            }}
          >
            {click ? (
              <img
                src={loader}
                className="loader"
                style={{ width: '23px', height: '23px' }}
              />
            ) : (
              'Check My Incentives'
            )}
          </div>
        )}
        {steps.length !== currentStep && (
          <div
            style={
              click ||
              (!isCheckAvailable &&
                steps.find((elem) => +elem.id === currentStep).title ===
                  'Investment Properties')
                ? {
                    background: '#F4D298',
                    cursor: 'context-menu',
                  }
                : { background: '' }
            }
            className={`calc__next`}
            onClick={() => {
              if (
                steps.find((elem) => +elem.id === currentStep).title ===
                'Investment Properties'
              ) {
                if (isCheckAvailable) {
                  nextStep();
                }
              } else {
                nextStep();
              }
            }}
          >
            {click ? (
              <img
                src={loader}
                className="loader"
                style={{ width: '23px', height: '23px' }}
              />
            ) : (
              ' Next'
            )}
          </div>
        )}
      </div>
    </div>
  );
};
