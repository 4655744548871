import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.png';
import x from '../../images/x.png';
import './header.scss';

export const Header = ({ user, loading = false, setConfirmModal }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    addEventListener('resize', (event) => {
        setScreenWidth(window.innerWidth);
    });
    const location = useLocation();
    const [showLeave, setShowLeave] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes('/calc')) {
            setShowLeave(true);
        } else if (location.pathname.includes('/privacy-policy')) {
            setShowLeave(false);
        } else if (location.pathname.includes('/terms')) {
            setShowLeave(false);
        } else {
            setShowLeave(false);
        }
    }, [location]);

    return (
        <>
            {screenWidth < 600 ? (
                <div
                    className={`header-phone ${
                        showLeave && !loading ? 'hide' : ''
                    }`}
                    style={
                        showLeave
                            ? { borderBottom: 'none' }
                            : { borderBottom: '1px solid #d2dee5' }
                    }
                >
                    <div className="container">
                        <div className="header-phone__wrap">
                            {showLeave && !loading ? (
                                <></>
                            ) : (
                                <img
                                    className={`header__logo ${
                                        location.pathname.includes('/home')
                                            ? ''
                                            : 'active'
                                    }`}
                                    src={logo}
                                    onClick={() => {
                                        if (
                                            location.pathname.includes('/calc')
                                        ) {
                                            setConfirmModal(true);
                                        } else {
                                            navigate(
                                                `/home${location?.search}`
                                            );
                                        }
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="header">
                    <div className="container">
                        <div className="header__wrap">
                            <img
                                className={`header__logo ${
                                    location.pathname.includes('/home')
                                        ? ''
                                        : 'active'
                                }`}
                                src={logo}
                                onClick={() => {
                                    if (location.pathname.includes('/calc')) {
                                        setConfirmModal(true);
                                    } else {
                                        navigate(`/home${location?.search}`);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
