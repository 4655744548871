import React, { useState } from 'react';
import { Slider } from '../Slider/Slider';
import './steps.scss';

export const ThirdStep = ({ formData, setFormData }) => {
    const screenWidth = window.innerWidth;

    return (
        <div className="step-page">
            <div className="step-page__title">Liabilities</div>

            <div
                className="step-page__block"
                style={screenWidth <= 600 ? { padding: '16px 0 0 0' } : {}}
            >
                <Slider
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_home_mortgage: e.target.value,
                        })
                    }
                    value={formData.total_home_mortgage}
                    title="1. Total Home Mortgage"
                    min={0}
                    max={3000000}
                    step={10000}
                />

                <Slider
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_personal_vehicle_loans: e.target.value,
                        })
                    }
                    value={formData.total_personal_vehicle_loans}
                    title="2. Total Personal / Vehicle Loans"
                    min={0}
                    max={200000}
                    step={5000}
                />
                <Slider
                    style={{ margin: '0' }}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            total_limit_combined_credit_cards: e.target.value,
                        })
                    }
                    value={formData.total_limit_combined_credit_cards}
                    title={
                        screenWidth > 600 ? (
                            <div>3. Total Limit Combined Credit Cards</div>
                        ) : (
                            <div>
                                3. Total Limit Combined
                                <br /> Credit Cards
                            </div>
                        )
                    }
                    min={0}
                    max={100000}
                    step={1000}
                />
            </div>
        </div>
    );
};
