import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../Hooks/useClickOutside';
import './CustomSelection.scss';

export const CustomSelection = ({
  options,
  onChange,
  value,
  errorText,
  placeholder = 'Age',
  style,
  styledForArrow,
  styledForOptions,
}) => {
  const [openSelection, setOpenSelection] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => setOpenSelection(false));

  console.log('openSelection', openSelection);

  return (
    <div className="custom-selection" ref={ref} style={style}>
      <div onClick={() => setOpenSelection((prev) => !prev)}>
        <input
          className={`custom-selection__field ${errorText ? 'error' : ''} ${
            openSelection ? 'hover' : ''
          }`}
          // disabled
          placeholder={placeholder}
          value={value}
          style={style}
        />
        <div className="custom-selection__error">{errorText}</div>
        <div
          className={`custom-selection__arrow ${openSelection ? 'open' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            setOpenSelection((prev) => !prev);
          }}
          style={styledForArrow}
        >
          ❮
        </div>
      </div>
      {openSelection && (
        <div className="custom-selection__options" style={styledForOptions}>
          {options.map((elem) => {
            return (
              <div
                key={elem}
                className="custom-selection__item"
                onClick={() => {
                  onChange(elem);
                  setOpenSelection(false);
                }}
              >
                {elem}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
