import React, { useEffect, useState } from 'react';
import { Header } from '../../components/Header/Header';
import arrowupright from '../../images/arrow-up-right.png';
import Rectangle from '../../images/Rectangle.png';
import Rectanglephone from '../../images/Rectangle-phone.svg';
import { Footer } from '../../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import './homepage.scss';

export const Homepage = ({ user, setCurrentStep }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    addEventListener('resize', (event) => {
        setScreenWidth(window.innerWidth);
    });
    const navigate = useNavigate();
    const token = localStorage.getItem('cow-token');
    document.title = 'Incentives Calculator';

    useEffect(() => {
        setCurrentStep(1);
    }, [location]);

    return (
        <>
            <Header user={user} />
            <div className="homepage">
                <div className="container">
                    <div>
                        <div className="homepage__title">
                            Want to pay down your home faster?
                        </div>
                        <div className="homepage__row">
                            <div className="homepage__text">
                                Instantly calculate if you qualify to pay down
                                your home in under 10 years, by leveraging
                                Government Tax Incentives that help you save &
                                redirect up to 50% of your tax dollars back into
                                your mortgage.
                            </div>
                            <div className="homepage__btn-block btn-block">
                                {screenWidth > 950 ? (
                                    <div className="btn-block__title">
                                        Find out in under 60 seconds
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div
                                    className={`btn-block__btn ${
                                        token ? '' : 'disabled'
                                    }`}
                                    onClick={() => {
                                        if (token) {
                                            navigate(
                                                `/calc${location?.search}`
                                            );
                                        }
                                    }}
                                >
                                    Instantly Calculate
                                    <img src={arrowupright} />
                                </div>
                                {screenWidth <= 950 ? (
                                    <div
                                        className="btn-block__title"
                                        style={{ padding: '10px 0 0 0' }}
                                    >
                                        Find out in under 60 seconds
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                        <img
                            src={screenWidth > 750 ? Rectangle : Rectanglephone}
                            className="homepage__reclame"
                        />
                    </div>
                </div>
            </div>
            <Footer user={user} />
        </>
    );
};
