import React, { useState } from 'react';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import './steps.scss';

export const FirstStep = ({ formData, setFormData }) => {
    const count = ['0', '1', '2', '3', '4', '5'];

    return (
        <div className="step-page">
            <div className="step-page__title">Living Situation</div>
            <div className="step-page__block">
                <div className="step-page__subtitle">1. Marital Status</div>
                <div className="step-page__block-with-checkboxes">
                    <div className="step-page__column">
                        <CustomCheckbox
                            name="married/de_facto"
                            title="Married / De Facto"
                            checked={
                                formData.marital_status === 'married/de_facto'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                        <CustomCheckbox
                            title="Engaged"
                            name="engaged"
                            checked={
                                formData.marital_status === 'engaged'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                        <CustomCheckbox
                            title="Single"
                            name="single"
                            checked={
                                formData.marital_status === 'single'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                    </div>
                    <div className="step-page__column">
                        <CustomCheckbox
                            title="Separated"
                            name="separated"
                            checked={
                                formData.marital_status === 'separated'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                        <CustomCheckbox
                            title="Divorced"
                            name="divorced"
                            checked={
                                formData.marital_status === 'divorced'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                        <CustomCheckbox
                            title="Widowed"
                            name="widowed"
                            checked={
                                formData.marital_status === 'widowed'
                                    ? true
                                    : false
                            }
                            onChange={(value) =>
                                setFormData({
                                    ...formData,
                                    marital_status: value,
                                })
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="step-page__block">
                <div className="step-page__subtitle">
                    2. Dependents (Under 18)
                </div>
                <div className="step-page__line"></div>
                <div className="step-page__count">
                    {count.map((elem) => {
                        return (
                            <div
                                key={elem}
                                className={`step-page__number ${
                                    +formData.dependant === +elem
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        dependant: elem,
                                    })
                                }
                            >
                                {elem}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="step-page__block">
                <div className="step-page__subtitle">
                    3. Number of investment Properties?
                </div>
                <div className="step-page__line"></div>
                <div className="step-page__count">
                    {count.map((elem) => {
                        return (
                            <div
                                key={elem}
                                className={`step-page__number ${
                                    +formData.number_investment_properties ===
                                    +elem
                                        ? 'active'
                                        : ''
                                }`}
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        number_investment_properties: elem,
                                    })
                                }
                            >
                                {elem}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
