import React from 'react';
import { Header } from '../../components/Header/Header';
import img from '../../images/403error.png';
import './error.scss';

export const AccessError = ({ user }) => {
    return (
        <>
            <Header user={user} />
            <div className="err-page">
                <div className="err-page__container">
                    <div className="err-page__error error">
                        <div className="error__mark">!</div>
                        <div className="error__text">Error</div>
                    </div>
                    <img src={img} className="err-page__img-access" />
                    <div className="err-page__title">Access Denied</div>
                    <div className="err-page__subtitle">
                        Sorry, but you don’t have permission to access requested
                        page
                    </div>
                    <div className="err-page__btn">Go back</div>
                </div>
            </div>
        </>
    );
};
